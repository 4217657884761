import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ProgramView from "../views/ProgramView.vue"
import ContactView from "../views/ContactView.vue"
import AboutView from "../views/AboutView.vue"
import InterView from "../views/InterView.vue"
import ApplyView from "../views/ApplyView"
import ThankView from "../views/ThankView"
import landingView from "../views/landingView"
import SkillsView from "../views/SkillsView"
import BbafinanceView from "../views/BbafinanceView"
import BbahrmView from "../views/BbahrmView"
import BbamarketingView from "../views/BbamarketingView"
import BcacsView from "../views/BcacsView"
import ThankyouView from "../views/ThankyouView"
import PageNotFoundView from "../views/PageNotFoundView"
import BlogsView from "../views/BlogsView"
import BlogsListing from "../views/BlogsListing"
import BcaCyberView from "../views/BcaCyberView"
import AcademicView from "../views/AcademicView"
import DisclousersView from "../views/DisclousersView"
import EnrollmentView from "../views/EnrollmentView"
// import BcaAnimationView from "../views/BcaAnimationView.vue"

const routes = [
  { path: '/:pathMatch(.*)*', 
  name: 'PageNotFound', 
  component: PageNotFoundView },

  { path: '/aboutus',
    redirect: '/about-us' },

  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/programs/online-bcom-accounting-and-finance',
    name: 'online-bcom-accounting-and-finance',
    component: ProgramView
  },
  {
    path: '/contactus',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/about-us',
    name: 'about',
    component: AboutView
  },
  {
    path: '/disclosures',
    name: 'Disclosures',
    component: DisclousersView
  },
  {
    path: '/academic-corner',
    name: 'Academic',
    component: AcademicView
  },
  {
    path: '/enrollment-data',
    name: 'Enrollment',
    component: EnrollmentView
  },
  {
    path: "/blogs/:blog/",
    name: "SelectedBlog",
    component: BlogsView,
    props:true
  },
  {
    path: "/blogs/",
    name: "Blogs",
    component: BlogsListing
  },
  {
    path: '/programs/online-bcom-international-finance-and-accounting',
    name: 'online-bcom-international-finance-and-accounting',
    component: InterView
  },

  {
    path: '/programs/online-bba-finance',
    name: 'online-bba-finance',
    component: BbafinanceView,
    redirect: "/"
  },

  {
    path: '/programs/online-bba-general-management',
    name: 'online-bba-general-management',
    component: BbahrmView,
    
  },
  {
    path: '/programs/online-bba-logistics-and-supply-chain-management',
    name: 'online-bba-logistics-and-supply-chain-management',
    component: BbamarketingView,
    
  },

  // {
  //   path: '/programs/online-bba-marketing',
  //   name: 'online-bba-marketing',
  //   component: BbamarketingView,
  //   redirect: "/"
  // },

  {
    path: '/programs/online-bca-computer-science-and-it',
    name: 'online-bca-computer-science-and-it',
    component: BcacsView
  },

 

  {
    path: '/apply',
    name: 'apply',
    component: ApplyView
  },

  {
    path: '/skills',
    name: 'skills',
    component: SkillsView
  },

  {
    path: '/brochure/bba-thankyou',
    name: 'bba-thankyou',
    component: ThankyouView
  },

  {
    path: '/brochure/bca-thankyou',
    name: 'bca-thankyou',
    component: ThankyouView
  },

  {
    path: '/brochure/bcom-thankyou',
    name: 'bcom-thankyou',
    component: ThankyouView
  },

  {
    path: '/lp/online-bachelors-degree',
    name: 'online-bachelors-degree',
    component: landingView
  },

  {
    path: '/lp/online-bachelors-degree/thankyou',
    name: 'thankyou',
    component: ThankView
  },

   // re-Direction

   {
    path: '/programs/online-bca-cloud-computing-and-cyber-security',
    name: 'online-bca-cloud-computing-and-cyber-security',
    component: BcaCyberView,
   
  },
  // {
  //   path: '/programs/online-bca-animation-and-graphic-design',
  //   name: 'online-bca-animation-and-graphic-design',
  //   component: BcaAnimationView,
   
  // },
  
];



 const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
   routes,
 });




export default router;
