<template>
  <div>
    <nav-view />
    <sticky-view />
    <section class="bg-[#94C34D]">
      <div
        class="max-w-7xl mx-auto flex flex-col lg:flex-row place-items-center"
      >
        <div
          class="text-white lg:w-[33%] w-full text-center lg:text-left mx-auto xl:w-auto"
        >
          <div class>
            <h1
              class="lg:text-4xl lg:divide-y pt-8 py-4 font-extrabold text-[30px] sm:text-[30px]"
            >
              Creating
              <br />Leaders of Tomorrow
            </h1>
            <h3 class="text-base font-bold">
              Live Online Sessions | Industry-relevant Specializations
            </h3>
            <div
              class="flex flex-row lg:flex-col w-full text-sm md:text-base text-left lg:block hidden"
            >
              <div class="lg:w-full flex lg:justify-start py-2">
                <h2 class="text-2xl">Online Degree Programs</h2>
              </div>
              <div class="lg:w-full flex lg:justify-start py-2">
                <h2 class="text-xl">BBA | BCA | B.Com</h2>
              </div>
            </div>

            <div
              class="flex flex-row lg:flex-col w-full text-sm md:text-base text-left divide-x lg:hidden"
            >
              <div class="lg:w-full flex lg:justify-start py-2">
                <h2 class="text-xl">Online Degree Programs</h2>
              </div>
              <div class="lg:w-full flex lg:justify-start py-2">
                <h2 class="text-lg">BBA | BCA | B.Com</h2>
              </div>
            </div>

            <div
              class="flex pt-8 pb-4 place-content-center lg:place-content-start mb-4"
            >
             
                <div @click="is_widget = true">
            <button
              class="px-6 py-2 bg-[#82C8DE] font-bold rounded-lg shadow-xl"
              href="https://api.onlineyenepoya.com/media/brochure/Yenapoya-overall-brochure.pdf" target="_blank"
            >Download Brochure</button></div>
              
            </div>
          </div>
          <!-- <div class="lg:hidden flex">
          <lsq-view />
          </div>-->
          <!-- <div class="lg:hidden flex justify-center">
            <img src="../assets/landing/banner_girl.webp" class="object-contain w-[80%]" />
          </div>-->
        </div>
        <div class="hidden lg:block relative lg:w-[33%]">
          <div class="w-full bottom-0">
            <img src="../assets/landing/banner_girl.webp" />
          </div>
        </div>
        <div class="flex lg:w-[33%]">
          <div class="w-[100%]">
            <lsqlp-view />
          </div>
        </div>
        <div class="lg:hidden flex justify-center">
          <img
            src="../assets/landing/banner_girl.webp"
            class="object-contain w-[80%]"
          />
        </div>
      </div>
    </section>
    <ranking-view />
    <section>
      <div class="max-w-7xl mx-auto text-center">
        <h2
          class="font-bold lg:text-3xl mt-8 text-[20px] sm:text-[20px] text-gray-700 p-2 pt-4"
        >
          Programs Offered
        </h2>
        <div class="flex flex-wrap justify-center">
          <div class="w-full">
            <ul
              class="flex mb-0 list-none flex-wrap justify-evenly gap-3 pt-3 pb-4 flex-row"
            >
              <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                  class="text-lg font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                  v-on:click="toggleTabs(1)"
                  v-bind:class="{
                    'text-white bg-gray-400': openTab !== 1,
                    'text-white bg-[#94C34D]': openTab === 1,
                  }"
                  >B COM</a
                >
              </li>
              <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                  class="text-lg font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                  v-on:click="toggleTabs(2)"
                  v-bind:class="{
                    'text-white bg-gray-400': openTab !== 2,
                    'text-white bg-[#94C34D]': openTab === 2,
                  }"
                  >BBA</a
                >
              </li>
              <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                  class="text-lg font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                  v-on:click="toggleTabs(3)"
                  v-bind:class="{
                    'text-white bg-gray-400': openTab !== 3,
                    'text-white bg-[#94C34D]': openTab === 3,
                  }"
                  >BCA</a
                >
              </li>
            </ul>
            <div
              class="relative flex flex-col min-w-0 break-words w-full mb-6 border-t-4 border-[#94C34D] bg-[#EEF6F9]"
            >
              <div class="px-4 py-5 flex-auto">
                <div class="tab-content tab-space justify-start text-left">
                  <div
                    v-bind:class="{
                      hidden: openTab !== 1,
                      block: openTab === 1,
                    }"
                  >
                    <p>
                      In today's complex business landscape, a combination of
                      accounting and finance expertise is essential. Our Online
                      B.Com (Bachelor of Commerce) program is a comprehensive
                      three-year degree that equips you with the necessary
                      skills for a wide range of managerial roles. With a focus
                      on areas such as finance, accounting, economics, law,
                      insurance, management, and taxation, our program prepares
                      you for the dynamic business environment. Gain the
                      competencies and leadership skills needed to excel in
                      strategic roles and gain a competitive edge in your
                      career.
                    </p>
                    <h3 class="text-[#94C34D] font-bold text-lg">
                      Specializations Offered:
                    </h3>
                    <h5>Accounting and Finance</h5>
                    <h5>
                      International Finance and Accounting (Accredited by ACCA,
                      UK)
                    </h5>
                  </div>
                  <div
                    v-bind:class="{
                      hidden: openTab !== 2,
                      block: openTab === 2,
                    }"
                  >
                    <p>
                      A Bachelor's degree in Business Administration offers
                      numerous benefits to learners seeking a managerial career
                      in the business world. The program equips learners with a
                      wide range of skills essential for managing and leading in
                      the business environment. It is considered a highly
                      recognized and sought-after qualification by employers
                      worldwide. The program nurtures entrepreneurship by
                      teaching students about business planning, market
                      research, risk management, and innovation.
                    </p>

                    <h3 class="text-[#94C34D] font-bold text-lg">
                      Specializations Offered:
                    </h3>
                    <h5>Human Resource Management</h5>
                    <h5>Finance</h5>
                    <h5>Marketing</h5>
                  </div>
                  <div
                    v-bind:class="{
                      hidden: openTab !== 3,
                      block: openTab === 3,
                    }"
                  >
                    <p>
                      An Online BCA Degree is a three-year program designed to
                      provide learners with the necessary knowledge of computer
                      application and software development, which are highly
                      relevant to modern industry scenarios. It focuses on
                      providing learners with a strong foundation in computer
                      science and information technology, along with a technical
                      foundation in programming languages, algorithms, data
                      structures, and database management
                    </p>
                    <h3 class="text-[#94C34D] font-bold text-lg">
                      Specializations Offered:
                    </h3>
                    <h5>Computer Science and Information Technology</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <skillhome-view />

    <section
      class="max-w-7xl mx-auto p-2 text-center justify-center object-cover lg:flex-row flex"
    >
      <div class="px-4 py-4 gap-6 justify-between">
        <h2
          class="lg:text-3xl text-[20px] sm:text-[20px] text-gray-700 text-center font-bold lg:py-6 py-4"
        >
          Why Choose Yenepoya Online
        </h2>
        <div class="px-4 py-8 gap-6 flex flex-col lg:flex-row">
          <div class="flex">
            <ul class="text-left">
              <li class="flex gap-4 p-2">
                <img
                  src="../assets/landing/why_choose_bullet.webp"
                  alt
                  class="h-5"
                />
                <p>Ranked 4th best university in Karnataka</p>
              </li>

              <li class="flex gap-4 p-2">
                <img
                  src="../assets/landing/why_choose_bullet.webp"
                  alt
                  class="h-5"
                />
                <p>
                  University has been awarded as best NSS unit and best NSS
                  program officer by the Ministry of Youth Affairs
                </p>
              </li>
            </ul>
          </div>

          <div class="flex">
            <ul class="text-left">
              <li class="flex gap-4 p-2">
                <img
                  src="../assets/landing/why_choose_bullet.webp"
                  alt
                  class="h-5"
                />
                <p>3000 learners on its rolls</p>
              </li>

              <li class="flex gap-4 p-2">
                <img
                  src="../assets/landing/why_choose_bullet.webp"
                  alt
                  class="h-5"
                />
                <p>
                  Awarded the Skill Tree - Great Place to Study"" at the House
                  of Commons, London, UK
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section class="p-4 lg:block hidden">
      <div class="max-w-7xl mx-auto text-center">
        <h2
          class="font-bold lg:text-3xl text-[20px] sm:text-[20px] text-gray-700 p-4"
        >
          Program Highlights
        </h2>
        <div class="relative w-full gap-5 mx-auto py-4">
          <div class>
            <div
              class="flex flex-col lg:flex-row w-full text-sm md:text-base text-center"
            >
              <div
                class="lg:w-full flex px-2 flex-col justify-center items-center lg:justify-start py-2"
              >
                <div class="p-6 mb-4 bg-[#94C34D] rounded-full">
                  <img
                    src="../assets/landing/program_highlights_icon_100_online.webp"
                    class="object-contain"
                    width="30"
                    alt
                  />
                </div>
                <div class="items-center px-8 text-center">
                  <h4 class="font-bold text-lg">100% Online</h4>
                  <p>
                    Designed to fit your busy schedule, our online curriculum
                    allows you to learn at your own pace and access course
                    materials from anywhere, at any time.
                  </p>
                </div>
              </div>

              <div
                class="lg:w-full flex px-2 flex-col justify-center items-center lg:justify-start py-2"
              >
                <div class="p-6 mb-4 bg-[#94C34D] rounded-full">
                  <img
                    src="/img/program_highlights_icon_expert_faculty.55c54aa6.webp"
                    class="object-contain"
                    alt
                    width="30"
                  />
                </div>
                <div class="items-center px-8 text-center">
                  <h4 class="font-bold text-lg">Expert Faculty</h4>
                  <p>
                    Learn from experienced educators and industry professionals
                    who provide real-world insights and practical applications
                    of analytics in various domains.
                  </p>
                </div>
              </div>

              <div
                class="lg:w-full flex px-2 flex-col justify-center items-center lg:justify-start py-2"
              >
                <div class="p-6 mb-4 bg-[#94C34D] rounded-full">
                  <img
                    src="/img/program_highlights_icon_handson_learning.b53e7165.webp"
                    class="object-contain"
                    alt
                    width="30"
                  />
                </div>
                <div class="items-center px-8 text-center">
                  <h4 class="font-bold text-lg">Hands-on Learning</h4>
                  <p>
                    Apply your knowledge to real-world challenges through case
                    studies, projects, and cutting-edge tools.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-blue-100 p-4 lg:hidden">
      <div class="max-w-7xl mx-auto bg-white rounded-lg shadow-lg text-center">
        <h2
          class="font-bold lg:text-3xl text-[20px] sm:text-[20px] text-gray-700 p-2 pt-4"
        >
          Program Highlights
        </h2>
        <div class="carousel relative w-[90%] mx-auto py-2">
          <div class="next absolute top-[40%] -right-4 z-10">
            <img src="../assets/right-arrow.png" alt />
          </div>
          <div class="prev absolute top-[40%] -left-4 z-[100]">
            <img src="../assets/left-arrow.png" alt />
          </div>
          <div class="slick">
            <div class>
              <div
                class="lg:w-full flex px-2 flex-col justify-center items-center lg:justify-start py-2"
              >
                <div class="items-center px-8 text-center">
                  <h4 class="font-bold text-lg">100% Online</h4>
                  <p>
                    Designed to fit your busy schedule, our online curriculum
                    allows you to learn at your own pace and access course
                    materials from anywhere, at any time.
                  </p>
                </div>
              </div>
            </div>

            <div class>
              <div
                class="lg:w-full flex px-2 flex-col justify-center items-center lg:justify-start py-2"
              >
                <div class="p-6 mb-4 bg-[#94C34D] rounded-full">
                  <img
                    src="../assets/landing/program_highlights_icon_100_online.webp"
                    class="object-contain"
                    width="30"
                    alt
                  />
                </div>
                <div class="items-center px-8 text-center">
                  <h4 class="font-bold text-lg">Expert Faculty</h4>
                  <p>
                    Learn from experienced educators and industry professionals
                    who provide real-world insights and practical applications
                    of analytics in various domains.
                  </p>
                </div>
              </div>
            </div>

            <div class>
              <div
                class="lg:w-full flex px-2 flex flex-col justify-center items-center lg:justify-start py-2"
              >
                <div class="p-6 mb-4 bg-[#94C34D] rounded-full">
                  <img
                    src="../assets/landing/program_highlights_icon_100_online.webp"
                    class="object-contain"
                    width="30"
                    alt
                  />
                </div>
                <div class="items-center px-8 text-center">
                  <h4 class="font-bold text-lg">Hands-on Learning</h4>
                  <p>
                    Apply your knowledge to real-world challenges through case
                    studies, projects, and cutting-edge tools.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section v-if="is_widget">

<div  class="fixed inset-0 z-50 overflow-y-auto w-full bg-black bg-opacity-60">
<div class="flex items-center justify-center min-h-screen">
<div class="relative bg-white rounded-lg h-[300px] w-[450px] z-60 p-4">
  <div class="npf_wgts" data-height="344px" :data-w="this.widget_id2"></div>
  <button
    @click="is_widget = false"
    class="absolute top-0 right-1 bg-white rounded-full p-1 z-50"
  >
    <svg
      class="h-6 w-6 fill-current text-gray-700 hover:text-gray-600 transition duration-150 ease-in-out"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <path
        fill-rule="evenodd"
        d="M10 8.586l4.95-4.95 1.414 1.414L11.414 10l4.95 4.95-1.414 1.414L10 11.414l-4.95 4.95-1.414-1.414L8.586 10 3.636 5.05l1.414-1.414L10 8.586z"
        clip-rule="evenodd"
      />
    </svg>
  </button>
 
  <div class>

<div class="flex items-center justify-center">
<div class="relative bg-white rounded-lg shadow-sm">
  <h4 class="font-bold text-lg px-2 py-2">
    Download Brochure
    <br />
  </h4>
  <div >
    <form
      class="space-y-3 p-4 font-normal"
    
      @submit.prevent="brochureSubmit"
    >
      <input
        type="text"
        placeholder="Enter Name *"
        id="FirstName"
        class="p-1 rounded-sm w-full border-gray-300"
        v-model="formInfo.firstName"
      />
      <input
        type="email"
        placeholder="Enter Email Address *"
        id="EmailAddress"
        class="p-1 rounded-sm w-full border-gray-300"
        v-model="formInfo.email"
      />

      <input
      type="tel"
      placeholder="Enter Mobile Number *"
      id="phone" maxlength="10"
      onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
      class="p-1 rounded-sm w-full border-gray-300"
      required
    />
   
      <!-- <select
        name="mx_Program"
        id
        value="mx_Program"
        aria-placeholder="Select Program"
        class="p-1 rounded-sm w-full border-gray-300 text-base hidden"
        required
      >
        <option value="B.Com">B.Com</option>
      </select>
      <select
        name="mx_Elective"
        id="mx_Elective"
        aria-placeholder="Select Elective"
        class="p-1 rounded-sm w-full border-gray-300 text-base hidden"
      >
        <option value="none">Select Specialization *</option>
        <option
          value=" Accounting and Finance"
        > Accounting and Finance</option>
      </select> -->

      <!-- <button
        type="submit"
        class="w-full text-white bg-[#9C9B9D] hover:bg-[#94C34D] rounded-lg px-4 py-2 text-center"
      >Download Brochure &#10148;</button> -->

    
        <button
        type="submit"
        class="w-full text-white bg-[#9C9B9D] hover:bg-[#94C34D] rounded-lg px-4 py-2 text-center"
      >Download Brochure &#10148;</button>
    </form>
  </div>
</div>
</div>

  </div>
</div>
</div>
</div>

</section>

    <faq-view />

    <lpfooter-view />
  </div>
</template>

<script>
import LpfooterView from "../components/LpfooterView.vue";
import NavView from "../components/NavView.vue";
import StickyView from "../components/StickyView.vue";
import RankingView from "../components/RankingView.vue";
import FaqView from "../components/FaqView.vue";
import LsqlpView from "@/components/LsqlpView.vue";
import SkillhomeView from "@/components/SkillhomeView.vue";
import axios from "axios";
export default {
  components: {
    NavView,
    FaqView,
    LpfooterView,
    RankingView,
    StickyView,
    LsqlpView,
    SkillhomeView,
  },

  props: ["widget_prop_id"],
    created() {
      this.widget_id = this.widget_prop_id
    },
    data() {
    return {
      is_widget: false,
      showMenu: false,
      openTab: 1,
      formInfo: {
        firstName: "",
        email: "",
        phone: ""
      }
    };
  },

  methods: {
    toggleTabs: function (tabNumber) {
      this.openTab = tabNumber;
    },


    brochureSubmit(el) {
      console.log("formInput:::", el);
      let dataJson = [
        {
          Attribute: "FirstName",
          Value: el.target.elements.FirstName.value
        },
        {
          Attribute: "EmailAddress",
          Value: el.target.elements.EmailAddress.value
        },
        {
          Attribute: "Phone",
          Value: el.target.elements.phone.value
        },
        // {
        //   Attribute: "mx_Program",
        //   Value: "B.Com"
        // },

        // {
        //   Attribute: "mx_Elective",
        //   Value: " Accounting and Finance"
        // }
      ];
      console.log(dataJson);
      axios
        .post(
          "https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$rca456e8ab1c2865cfece22cda05cbb0c&secretKey=3c0ddb27e7dc562e5c6c08bb8c11b02a9019e041",
          dataJson,
          { cross: true }
        )
        .then(async() => {
          
          await fetch(`https://api.onlineyenepoya.com/media/brochure/Yenapoya-overall-brochure.pdf`)
              .then((response) => response.blob())
              .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const a = document.createElement("a");
                a.href = url;
                a.download = `Yenapoya-overall-brochure.pdf`;
                a.click();
              });
          setTimeout(()=>{
            window.location.href = "/brochure/bba-thankyou";
          },2000)
        })
        .catch((error) => {
          // console.log(error.response.data.ExceptionMessage)
          console.log(error);
        });
    },
  },

  name: "pink-tabs",
 
 
};
</script>

<style></style>
