<template>
  <div>
    <section class="p-4 max-w-7xl mx-auto">
      <h2 class="text-3xl text-gray-700 font-bold lg:text-center te p-4 lg:text-3xl text-[20px] sm:text-[20px]">
        Frequently Asked Questions
      </h2>
      <div class="lg:flex lg:gap-4">
        <div>
          <div data-accordion="collapse" class="text-left">
            <h2 id="accordion-collapse-heading-1" class="pt-4 text-left text-left moreBox">
              <button
                class="flex items-start justify-between w-80 md:w-[480px] xl:w-[600px] mx-auto px-5 py-3 border border-gray-200 rounded-xl bg-white dark:bg-white"
                id="b0"
                data-accordion-target="#accordion-collapse-body-1"
                aria-expanded="false"
                aria-controls="accordion-collapse-body-1"
              >
                <span class="text-black text-left"
                  >Is the online degree program offered by Yenepoya (Deemed-to-be University) UGC entitled?</span
                >
                <img src="../assets/about/read-more.png" width="20" alt="" />
              </button>
            </h2>
            <div
              id="accordion-collapse-body-1"
              class="hidden"
              aria-labelledby="accordion-collapse-heading-1"
            >
              <div
                class="p-5 border border-t-0 border-gray-200 w-80 md:w-[480px] xl:w-[600px] mx-auto rounded-b-xl bg-white"
              >
                <p class="mb-2">
                  Yes, the University is entitled by the University Grants Commission (UGC) to offer online degree programs.
                </p>
              </div>
            </div>
            <h2 id="accordion-collapse-heading-2" class="pt-4 text-left moreBox">
              <button
                class="flex items-start justify-between w-80 md:w-[480px] xl:w-[600px] mx-auto p-5 border border-gray-200 rounded-xl bg-white dark:bg-white"
                id="b1"
                data-accordion-target="#accordion-collapse-body-2"
                aria-expanded="false"
                aria-controls="accordion-collapse-body-2"
              >
                <span class="text-black text-left"
                  >Does an online degree hold the same value as a offline degree?</span
                >
                <img src="../assets/about/read-more.png" width="20" alt="" />
              </button>
            </h2>
            <div
              id="accordion-collapse-body-2"
              class="hidden"
              aria-labelledby="accordion-collapse-heading-2"
            >
              <div
                class="p-5 border border-t-0 border-gray-200 w-80 md:w-[480px] xl:w-[600px] mx-auto rounded-b-xl bg-white"
              >
                <p class="mb-2 text-left">
                 Yes. UGC notifies that online degrees are considered equivalent and holds the same value as offline ones.
                </p>
              </div>
            </div>
            <h2 id="accordion-collapse-heading-3" class="pt-4 text-left moreBox">
              <button
                class="flex items-start justify-between w-80 md:w-[480px] xl:w-[600px] mx-auto p-5 border border-gray-200 rounded-xl bg-white dark:bg-white"
                id="b2"
                data-accordion-target="#accordion-collapse-body-3"
                aria-expanded="false"
                aria-controls="accordion-collapse-body-3"
              >
                <span class="text-black text-left"
                  >Do the Online Degrees have a validity period?</span
                >
                <img src="../assets/about/read-more.png" width="20" alt="" />
              </button>
            </h2>
            <div
              id="accordion-collapse-body-3"
              class="hidden"
              aria-labelledby="accordion-collapse-heading-3"
            >
              <div
                class="p-5 border border-t-0 border-gray-200 w-80 md:w-[480px] xl:w-[600px] mx-auto rounded-b-xl bg-white"
              >
                <p class="mb-2 text-left">
                  No. The Online Degree that you will receive from Yenepoya (Deemed-to-be University) is valid for lifetime.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div data-accordion="collapse">
            <h2 id="accordion-collapse-heading-4" class="pt-4 text-left moreBox lg:block hidden">
              <button
                class="flex items-start justify-between w-80 md:w-[480px] xl:w-[600px] mx-auto p-5 border border-gray-200 rounded-xl bg-white dark:bg-white"
                id="b3"
                data-accordion-target="#accordion-collapse-body-4"
                aria-expanded="false"
                aria-controls="accordion-collapse-body-4"
              >
                <span class="text-black text-left"
                  >What is the mode of learning?</span
                >
                <img src="../assets/about/read-more.png" width="20" alt="" />
              </button>
            </h2>
            <div
              id="accordion-collapse-body-4"
              class="hidden"
              aria-labelledby="accordion-collapse-heading-4"
            >
              <div
                class="p-5 border border-t-0 border-gray-200 w-80 md:w-[480px] xl:w-[600px] mx-auto rounded-b-xl bg-white"
              >
                <p class="mb-2 text-left">
                 You will have 24*7 access to self-learning material and pre-recorded lectures on our Learning Management System (LMS), which can be accessed on any device.
                </p>
              </div>
            </div>
            <h2 id="accordion-collapse-heading-5" class="pt-4 text-left moreBox lg:block hidden">
              <button
                class="flex items-start justify-between w-80 md:w-[480px] xl:w-[600px] mx-auto p-5 border border-gray-200 rounded-xl bg-white dark:bg-white"
                id="b4"
                data-accordion-target="#accordion-collapse-body-5"
                aria-expanded="false"
                aria-controls="accordion-collapse-body-5"
              >
                <span class="text-black text-left"
                  >How many credits does the program have?</span
                >
                <img src="../assets/about/read-more.png" width="20" alt="" />
              </button>
            </h2>
            <div
              id="accordion-collapse-body-5"
              class="hidden"
              aria-labelledby="accordion-collapse-heading-5"
            >
              <div
                class="p-5 border border-t-0 border-gray-200 w-80 md:w-[480px] xl:w-[600px] mx-auto rounded-b-xl bg-white"
              >
                <p class="mb-2 text-left">
                  132 credits.
                </p>
              </div>
            </div>
            <h2 id="accordion-collapse-heading-6" class="pt-4 text-left moreBox  lg:block hidden">
              <button
                class="flex items-start justify-between w-80 md:w-[480px] xl:w-[600px] mx-auto p-5 border border-gray-200 rounded-xl bg-white dark:bg-white"
                id="b5"
                data-accordion-target="#accordion-collapse-body-6"
                aria-expanded="false"
                aria-controls="accordion-collapse-body-6"
              >
                <span class="text-black text-left"
                  >Can I take the examination from anywhere?</span
                >
                <img src="../assets/about/read-more.png" width="20" alt="" />
              </button>
            </h2>
            <div
              id="accordion-collapse-body-6"
              class="hidden"
              aria-labelledby="accordion-collapse-heading-6"
            >
              <div
                class="p-5 border border-t-0 border-gray-200 w-80 md:w-[480px] xl:w-[600px] mx-auto rounded-b-xl bg-white"
              >
                <p class="mb-2 text-left">
                  Yes, online proctored examinations will be conducted.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-center mt-4">
            <div class="flex align-middle place-content-end gap-2 lg:hidden" id="loadMore">
             
              <button class="readmore-btn text-gray-600"><a href="#">View More</a></button><img src="../assets/about/read-more.png" width="25" alt="">
            </div>
          </div>
      </div>
    </section>
  </div>
</template>



<script>
import $ from "jquery";
export default {
   
    mounted() {
        $(document).ready(function () {
      $(".moreBox").slice(0, 3).show();
      if ($(".accordion-item:hidden").length != 0) {
        $("#loadMore").show();
      }
      $("#loadMore").on("click", function (e) {
        e.preventDefault();
        $(".moreBox:hidden").slice(0, 4).slideDown();
        if ($(".moreBox:hidden").length == 0) {
          $("#loadMore").fadeOut("slow");
        }
      });
    });
    },
}

   
</script>

<style>
.moreBox, .moreBox1 {
  font-size: 17px !important;
  font-weight: 400 !important;
}
</style>
