<template>
    <div class="fixed inset-0 z-50 overflow-y-auto w-full bg-black bg-opacity-60">
      <div class="flex items-center justify-center min-h-screen">
        <div class="relative bg-white rounded-lg h-[300px] w-[450px] z-60 p-4">
          <div class="npf_wgts" data-height="344px" :data-w="this.widget_id1"></div>
          <button
            @click="$emit('closeWidget')"
            class="absolute top-0 right-1 bg-white rounded-full p-1"
          >
            <svg
              class="h-6 w-6 fill-current text-gray-700 hover:text-gray-600 transition duration-150 ease-in-out"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                fill-rule="evenodd"
                d="M10 8.586l4.95-4.95 1.414 1.414L11.414 10l4.95 4.95-1.414 1.414L10 11.414l-4.95 4.95-1.414-1.414L8.586 10 3.636 5.05l1.414-1.414L10 8.586z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
         
          <div class>
  <down-view />
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
import DownView from '../components/DownView.vue';
  export default {
    components:{
      DownView
    },
    props: ["widget_prop_id1"],
    created() {
      this.widget_id1 = this.widget_prop_id
    },
  
   
  };
  </script>
  <style></style>