<template>
    <div>
        <navigation-view />
      <sticky-view />

      <section>
      <div class="bg-[#94C34D]">
        <div class="flex max-w-5xl overflow-hidden mx-auto place-items-center text-white justify-between">
          <!-- <div class="text-left lg:text-left pt-6">
            <span class="lg:text-[2rem] text-[28px] font-bold lg:leading-[60px] sm:text-[30px] leading-10" >
              Blogs 
          
            </span>
 
          </div> -->
          <div class="lg:block hidden">
            <img src="../assets/Blog-Banner.webp" class="" alt />
          </div>
          <div class="lg:hidden">
            <img src="../assets/Blog-mob.webp" class="" alt />
          </div>
        </div>
      </div>
    </section>
      <div class="max-w-6xl mx-auto w-full h-full">
        <div class="bg-white py-2 sm:py-4 md:h-[20%]">
          <h1
            class="text-gray-500 flex-wrap text-xs lg:text-sm 2xl:text-base text-start font-thin flex gap-1 lg:gap-4 items-center px-6 lg:px-2"
          >
            <router-link :to="{ name: 'home' }">
              <span class="font-normal"> Home </span></router-link
            >
            <span class="font-normal"> > </span>
            <span class="font-normal"> Blogs</span>
          </h1>
        </div>
      </div>
      <div class="bg-[#f5f5f5] py-4 w-full">
        <div
          class="max-w-6xl mx-auto w-full h-full flex flex-row overflow-x-scroll lg:overflow-x-auto gap-6 px-6 lg:px-2"
        >
          <button
            @click="this.blogsCategory"
            :class="[this.selected_category == 'all' ? 'text-gray-500' : '']"
            class="font-semibold text-black text-sm"
          >
            All
          </button>
          <template v-for="category in this.blog_list" :key="category">
            <button
              class="font-semibold text-black text-sm whitespace-nowrap"
              :class="[
                this.selected_category == category.id ? 'text-gray-500' : '',
              ]"
              @click="blogsElements(category.id)"
            >
              {{ category.name }}
            </button>
          </template>
        </div>
      </div>
  
      <div class="max-w-6xl mx-auto py-12 my-8">
        <div class="flex flex-wrap gap-5 justify-start px-8 lg:px-2">
          <template v-for="el in this.blog_elements" :key="el">
            <div
              @click="this.selectedBlog(el)"
              class="w-full md:w-[48%] lg:w-[32%] cursor-pointer shadow-md rounded-md"
            >
              <div class="flex flex-col gap-2 pb-2">
                <div class="w-full relative rounded-t-md">
                  <img :src="el.image" alt="" class="w-full rounded-md h-[200px]" />
  
                  <!-- <div
                      class="absolute bottom-0 bg-black text-yellow-500 text-sm px-2 py-0.5 rounded"
                    >
                      Finance
                    </div> -->
                </div>
  
                <div class="px-2">
                  <div class="flex flex-row justify-between px-2">
                    <p class="text-xs lg:text-sm font-normal">  {{ dateFormat(el.created_at) }}</p>
               
                    <div
                class="text-gray-600 border border-gray-400 rounded-md w-fit px-2 py-1 text-xs lg:text-sm"
              >
                {{ this.blog_value[0].category.name }}
                
              </div>
                    <!-- <p class="text-xs lg:text-sm font-normal">By Arjun Mohan</p> -->
                  </div>
  
                  <h1
                    class="text-start font-semibold text-sm lg:text-base mt-2 px-2"
                  >
                    {{ el.title }}
                  </h1>
  
                  <p
                    class="text-xs lg:text-sm mt-1 line-clamp-3 text-justify px-2"
                  >
                    {{ el.short_desc }}
                  </p>
<p
class="text-sm text-white bg-[#81C8DC] p-1 pr-2 pl-2 left-0 w-[100px] rounded-xl"
>

  <a href="" class=" hover:text-black">Read more</a>
</p>
                </div>
              </div>
            </div>
          </template>
        </div>
  
        <div class="flex justify-center mt-4">
            <div class="flex align-middle place-content-end gap-2 lg:hidden" id="loadMore8">
             
              <button class="readmore-btn text-gray-600"><a href="#">View More</a></button><img src="../assets/about/read-more.png" width="25" alt="">
            </div>
          </div>
      </div>
      <footer-view />
    </div>
  </template>
  
  <script>
  import $ from "jquery";
  import FooterView from "../components/FooterView.vue";
  import NavigationView from "../components/NavigationView.vue";
  import StickyView from "../components/StickyView.vue";
  import axios from "axios";
  
  export default {
    name: "BlogListing",
    data() {
      return {
        selected_category: "",
        blog_list: [],
        blog_elements: [],
        accordionUpdate1: [],
        accordionUpdate2: [],
      };
    },
    components: {
        StickyView, FooterView, NavigationView
    },
    async created() {
      await this.blogsCategory();

    
    },
    methods: {

      dateFormat(date) {
      var options = { year: "numeric", month: "long", day: "numeric" };
      var today = new Date(date);
      var result = today.toLocaleDateString("en-US", options);
      return result;
    },
  
      async blogsCategory() {
        await axios
        .get(`https://api.onlineyenepoya.com/blog-category/list/`)
          .then((resp) => {
            if (resp.data.status == 200) {
              this.blog_list = resp.data.data;
              this.blogsElements();
            }
          })
          .catch((e) => {
            console.error(e.message);
          });
      },
  
      async blogsElements(category_id = "") {
        if (category_id != "") {
          this.selected_category = category_id;
        } else {
          this.selected_category = "all";
        }
        await axios
        .get(`https://api.onlineyenepoya.com/blog/list/?category_id=${category_id}`)
          .then((resp) => {
            if (resp.data.status == 200) {
              this.blog_elements = resp.data.data;
              this.blog_value = resp.data.data;
            }
          })
          .catch((e) => {
            console.error(e.message);
          });
      },
      async selectedBlog(blog) {
        this.$router.push({
          name: "SelectedBlog",
          params: {
            blog: blog.slug,
          },
        });
      },
    },
    mounted() {
        $(document).ready(function () {
      $(".moreBox").slice(0, 3).show();
      if ($(".accordion-item:hidden").length != 0) {
        $("#loadMore8").show();
      }
      $("#loadMore8").on("click", function (e) {
        e.preventDefault();
        $(".moreBox:hidden").slice(0, 4).slideDown();
        if ($(".moreBox:hidden").length == 0) {
          $("#loadMore8").fadeOut("slow");
        }
      });
    });
    },
  
  };
  </script>
  