<template>
  <div class="w-full h-full">
    <navigation-view />
    <sticky-view />

    <section>
      <div class="bg-[#94C34D]">
        <div class="flex max-w-5xl overflow-hidden mx-auto place-items-center text-white justify-between">
          <!-- <div class="text-left lg:text-left pt-6">
            <span class="lg:text-[2rem] text-[28px] font-bold lg:leading-[60px] sm:text-[30px] leading-10" >
              Blogs 
          
            </span>
 
          </div> -->
          <div class="lg:block hidden">
            <img src="../assets/Blog-Banner.webp" class="" alt />
          </div>
          <div class="lg:hidden">
            <img src="../assets/Blog-mob.webp" class="" alt />
          </div>
        </div>
      </div>
    </section>
    <div class="flex max-w-7xl mx-auto place-items-center text-white justify-between">
      <div class="mx-auto text-center lg:text-left pt-6">
        <div v-if="this.blog_value.length > 0" class="bg-white">
          <section>
            <div class="max-w-6xl 2xl:max-w-[130rem] mx-auto px-2 lg:px-6 w-full h-full">
              <!-- breadcrumbs  -->
              <div class="py-2 sm:py-4 md:h-[20%]">
                <h1
                  class="text-gray-500 flex-wrap text-xs lg:text-sm 2xl:text-base text-start font-thin flex gap-1 lg:gap-4 items-center"
                >
                <router-link :to="{ name: 'home' }">
              <span class="font-normal"> Home </span></router-link
            >
                  <span class="font-normal">></span>
                  
                  <router-link :to="{ name: 'Blogs' }">  <span class="font-normal">Blogs</span></router-link
            >
                  <!-- <span class="font-normal"> > </span> -->
                  <!-- <span class="font-normal">
                  {{ this.blog_value[0].category.name }}</span-->

                  <span class="font-normal">> {{ this.blog_value[0].title }}</span>
                </h1>
              </div>

              <div class="relative w-full px-5 my-10  font-ibm tracking-normal text-[#161616]">

<div class="w-full max-w-6xl mx-auto flex flex-col lg:flex-row gap-10">
  <div class="w-full lg:w-calc(100%-480px) flex flex-col gap-3">
    <div class="w-full">
                <div class="flex flex-row mt-4">
                  <div
                  class="w-full flex-row  font-bold text-gray-700 text-xl lg:text-4xl lg:my-3"
                >{{ this.blog_value[0].title }}</div>
              </div>
              <div class="flex flex-row mt-4">
                  <div
                    class="text-gray-600 border border-gray-400 rounded-md w-fit px-2 py-1 text-xs lg:text-sm"
                  >{{ this.blog_value[0].category.name }}</div>
                  <div
                    class="text-xs ml-4 text-gray-400 lg:text-sm"
                  >{{ dateFormat(blog_value[0].created_at) }}</div>
                </div>
  

                <!-- <div class="text-sm lg:text-base font-semibold text-gray-600">
                  By Arjun Mohan
                </div>-->
              </div>


              <div class="flex flex-col lg:flex-row gap-2">
                <div class="w-full">
                  <img :src="this.blog_value[0].banner" alt class="w-full" />
                </div>
              </div>
          
              <div
                v-html="this.blog_value[0].desc"
                class="text-gray-500 text-start mt-4 border-b-2 border-gray-300 text-sm lg:text-base 2xl:text-lg"
              ></div>
              <div class="flex flex-row gap-4 py-4">
                <h2 class="text-sm lg:text-base text-gray-400 font-bold">Share this article</h2>
                <div class="flex flex-row items-center gap-3 lg:gap-5">
                  <div
                    @click="shareOnTwitter"
                    class="cursor-pointer w-[32px] h-[32px] rounded-full"
                  >
                    <img src="../assets/twitter.png" alt class="object-contain h-3 lg:h-4" />
                  </div>
                  <div
                    @click="shareOnLinkedIn"
                    class="cursor-pointer w-[32px] h-[32px] rounded-full"
                  >
                    <img src="../assets/linkedin.png" alt class="object-contain h-3 lg:h-4" />
                  </div>
                  <div
                    @click="shareOnFacebook"
                    class="cursor-pointer w-[32px] h-[32px] rounded-full"
                  >
                    <img src="../assets/facebook.png" alt class="object-contain h-3 lg:h-4" />
                  </div>
                </div>
              </div>
              </div>
              <div class="shrink-0 lg:mt-2">
          <h6 class="text-2xl sm:text-3xl font-bold mt-4 font-sans leading-normal">
            Related Blogs
          </h6>
          <div v-if="getRelatedBlogList.length > 0" class="flex w-full flex-wrap  lg:flex-col gap-10 sm:gap-5 mt-7">
            <template v-for="el,index in getRelatedBlogList" :key="el">
              <a v-if="index < 4" :href="`this.selectedBlog(el)`"
                class="flex flex-col sm:w-[18rem] items-start gap-8 rounded  border border-gray-200 hover:shadow-xl">
                <div class="w-full relative rounded-t-md">
                  <img :src="el.image" alt="" class="w-full rounded-md h-[250px]" />
  
                  <!-- <div
                      class="absolute bottom-0 bg-black text-yellow-500 text-sm px-2 py-0.5 rounded"
                    >
                      Finance
                    </div> -->
                </div>

                <div class="px-2">
                  <div class="flex flex-row justify-between px-2">
                    <p class="text-xs lg:text-sm font-normal">  {{ dateFormat(el.created_at) }}</p>
               
                    <div
                class="text-gray-600 border border-gray-400 rounded-md w-fit px-2 py-1 text-xs lg:text-sm"
              >
                {{ this.blog_value[0].category.name }}
                
              </div>
                    <!-- <p class="text-xs lg:text-sm font-normal">By Arjun Mohan</p> -->
                  </div>
  
                  <h1
                    class="text-start font-semibold text-sm lg:text-base mt-2 px-2"
                  >
                    {{ el.title }}
                  </h1>
  
                  <p
                    class="text-xs lg:text-sm mt-1 line-clamp-3 text-justify px-2"
                  >
                    {{ el.short_desc }}
                  </p>
<p
class="text-sm text-white bg-[#81C8DC] p-1 pr-2 pl-2 mb-4 mt-4 left-0 w-[100px] rounded-xl"
>

  <a href="" class=" hover:text-black">Read more</a>
</p>
                </div>
              </a>
            </template>
          </div>

        </div>
              </div>
              </div>
            </div>
          </section>
          <section>
            <div v-if="this.blog_value.length > 0" class="bg-[#fafafa] w-full hidden">
              <BlogsCarousel />
            </div>
          </section>
        </div>
      </div>
    </div>
    <FooterView />
  </div>
</template>
  <script>
import axios from "axios";
import FooterView from "../components/FooterView.vue";
import NavigationView from "../components/NavigationView.vue";
import StickyView from "../components/StickyView.vue";
import BlogsCarousel from "../components/Blogs/BlogsCarousel.vue";
export default {
  data() {
    return {
      blog_value: [],
      blogRelatedList: [],
   
    };
  },
  props: ["blog", "category"],
  components: {
    NavigationView,
    FooterView,
    StickyView,
    BlogsCarousel
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.blog != from.params.blog) {
      this.blogsCall();
    } else {
      next();
    }
  },
  async created() {
    console.log("hello");
    await this.blogsCall();
    this.getRelatedBlog();
  },
  computed: {
    getRelatedBlogList() {
      return this.blogRelatedList.filter((item) => item.slug != this.blog);
    },
  },
  methods: {
    dateFormat(date) {
      console.log(this.blog);
      var options = { year: "numeric", month: "long", day: "numeric" };
      var today = new Date(date);
      var result = today.toLocaleDateString("en-US", options);
      return result;
    },
    async blogsCall() {
      console.log(this.blog);
      await axios

        .get(`https://api.onlineyenepoya.com/blog/list/?slug=${this.blog}`)
        .then(resp => {
          if (resp.data.status == 200) {
            this.blog_value = resp.data.data;
            console.log(this.blog_value, "this.blog_value");
          }
        })
        .catch(e => {
          console.error(e.message);
        });
    },

    async getRelatedBlog() {
      await axios.get(`https://api.onlineyenepoya.com/blog/list/`).then((resp) => {
        if (resp.data && resp.data.data.length > 0) {
          this.blogRelatedList = resp.data.data;
        }
      });
    },

    shareOnFacebook() {
      const url = "https://onlineyenepoya.com/" + this.$route.fullPath;
      const title = this.blog_value.title;
      const encodedUrl = encodeURIComponent(url);
      const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}&t=${title}&quote=`;
      window.open(facebookShareUrl, "_blank");
    },
    shareOnLinkedIn() {
      const url = "https://onlineyenepoya.com/" + this.$route.fullPath;
      const title = this.blog_value.title;
      const encodedUrl = encodeURIComponent(url);
      const linkedInShareUrl = `https://www.linkedin.com/shareArticle?url=${encodedUrl}&t=${title}&quote=`;
      window.open(linkedInShareUrl, "_blank");
    },
    shareOnTwitter() {
      const url = "https://onlineyenepoya.com/" + this.$route.fullPath;
      const title = this.blog_value.title;
      const encodedUrl = encodeURIComponent(url);
      const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodedUrl}&t=${title}&quote=`;
      window.open(twitterShareUrl, "_blank");
    }
  }
};
</script>
  <style>
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  margin-bottom: 0px;
  padding: 0;
  font-weight: 700;
  line-height: 1.2;
}

h2 {
  font-size: 22px !important;
  font-weight: 600 !important;
  line-height: 30px;
  margin-bottom: 10px !important;
  margin-top: 10px !important;
  color: #2a2a2a;
}

h4 {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
  color: #2a2a2a;
}

p {
  font-size: 16px !important;
  font-weight: 500;
  line-height: 22px;
  padding-bottom: 10px;
  text-align: justify;
  

}

.redlist ol,
li {
  list-style-type: none;
  list-style-position: outside;
  font-size: 16px !important;
}
</style>