<template>
  <div class="about">
    <navigation-view />
    <sticky-view />
     <section>
      <div class="bg-[#94C34D]">
        <div class="flex max-w-7xl mx-auto place-items-center text-white justify-between">
          <div class="mx-auto text-center lg:text-left pt-6">
            <span class="lg:text-[3rem] text-[28px] font-bold lg:leading-[60px] sm:text-[30px] leading-10" >
              About 
            Yenepoya   <br /><span class="lg:text-2xl text-lg">(Deemed to be University)</span>
            </span>
            <hr class="max-w-[20%] bg-white h-1 rounded-xl my-4 mx-auto lg:mx-0" />
         
              <h3 class="lg:text-2xl font-bold text-[20px] sm:text-[20px] lg:p-1 p-3 sm:p-3">
                Creating Leaders of tomorrow
              </h3>
              <div class="w-[70%] bg-white rounded-lg shadow-lg text-center mt-8 h-25 md:block lg:block hidden">
                <div class="p-1 m-1 flex">
                  <img src="../assets/aboutus/banner_logo.webp" alt class="mx-auto w-[80%]" />
                </div>
              </div>
        
            <div class="relative sm:block lg:hidden">
              <div class="mx-auto w-[70%] bg-white rounded-lg shadow-lg text-center m-4 h-25 md:block lg:hidden">
                <div class="p-1 m-1 flex">
                  <img src="../assets/aboutus/banner_logo.webp" alt class="mx-auto w-[80%]" />
                </div>
              </div>

              <div class="flex justify-center mx-auto">
                <img src="../assets/aboutus/banner_img.webp" class="w-[18rem] -mr-1 lg:mr-0" alt />
              </div>
            </div>
          </div>
          <div class="relative hidden sm:hidden lg:block">
            <img src="../assets/aboutus/banner_img.webp" class="w-[35rem]" alt />
          </div>
        </div>
      </div>
    </section>

    <about-view />
    <ranking-view />
    <skills-view />

    <section>
      <div class="w-full mx-auto max-w-7xl py-10">
        <div class="flex flex-col lg:flex-row justify-evenly lg:gap-10 gap-4 sm:gap-4">
          <div class="flex lg:gap-10 gap-4 sm:gap-4 lg:items-start items-start sm:items-start w-full linear">
            <div>
              <img src="../assets/aboutus/vision.webp" alt class="lg:h-40  w-[500px]" />
            </div>
            <div>
              <p class="text-left">
              To provide access to quality higher education, ensuring equity,
                creating a vibrant knowledge capital and inspiring the leaders
                of tomorrow who can take the country to the forefront of the
                developed nations.
              </p>
            </div>
          </div>
          <div class="flex lg:gap-10 gap-4 sm:gap-4 lg:items-start items-start sm:items-start w-full py-4 ">
            <div>
              <img src="../assets/aboutus/mission.webp" alt class="ml-2 lg:h-40 w-[720px]" />
            </div>
            <div>
              <p class="text-left"> To foster academic excellence and global competencies among
                learners by creating an environment for the new generation of
                learners where they can gain knowledge and skills through
                meaningful research, adopting latest methods of pedagogy and
                incorporating modern principles of academics integrated with
                highest ethical standards.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="lg:pt-40 pt-8 sm:pt-8 pb-10">
      <div class="lg:w-full w-[90%] sm:w-[90%] mx-auto max-w-7xl bg-[#82C9DF] rounded-lg lg:p-16 p-4 relative m-4 sm:p-4">
        <div class="flex flex-col gap-5">
          <div>
            <h3 class="text-white font-bold text-4xl text-left lg:block hidden">
              Join the Online Degree Program Now
            </h3>
             <h3 class="text-white font-bold text-[18px] text-center lg:hidden sm:block">Join the Online Degree Program Now</h3>
          </div>
          <div class="flex flex-col lg:flex-row gap-5">
            <h5 class="text-white font-bold lg:text-lg text-[16x] px-2 py-2 bg-[#9C9A9B] rounded-xl border-2" >
              <a href="https://apply.onlineyenepoya.com/" target="_blank">B.COM</a>
            </h5>
            <h5 class="text-white font-bold lg:text-lg text-[16px] px-2 py-2 bg-[#9C9A9B] rounded-xl border-2 " >
              <a href="https://apply.onlineyenepoya.com/" target="_blank"
                          >BBA</a>
            </h5>
            <h5 class="text-white font-bold lg:text-lg text-[16px] px-2 py-2 bg-[#9C9A9B] rounded-xl border-2 " >
              <a href="https://apply.onlineyenepoya.com/" target="_blank"
                          >BCA</a>
            </h5>
           
          </div>
        </div>
        <div class="absolute right-0 bottom-0 ">
          <!-- <img src="../assets/aboutus/join_our_degree.webp" class="lg:hidden sm:block w-[50%] float-right" alt /> -->
          <img src="../assets/aboutus/join_our_degree.webp" class="lg:block hidden" alt />
        </div>
      </div>
    </section>
    <footer-view />
  </div>
</template>

<script>
import NavigationView from "../components/NavigationView.vue";
import FooterView from "../components/FooterView.vue";
import AboutView from "../components/AboutView.vue";
import RankingView from "../components/RankingView.vue";
import StickyView from '../components/StickyView.vue'


export default {
  components: { NavigationView, StickyView, FooterView, AboutView, RankingView },

};
</script>

<style>
.linear {
  padding: 15px;
  border-right: 3px solid;
  border-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgb(226, 226, 226) 48%,
    rgba(255, 255, 255, 0) 100%
  );
  border-image-slice: 3;
}

.faculty {
  list-style-image: url("../assets/faculty/pointer.png");
}
</style>
