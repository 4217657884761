<template>
  <div>
    <!-- About us -->
    <section class="max-w-7xl mx-auto p-4">
      <h1
        class="font-extrabold text-gray-700 p-4  lg:text-3xl text-[20px] sm:text-[20px]"
      >
        About Yenepoya (Deemed to be University)
      </h1>
      <div class="lg:flex gap-6 place-items-center">
        <img
          src="../assets/about/university.png"
          width="550"
          class="mx-auto p-4"
          alt=""
        />
        <span class="text-left readmore-section">
          <p class="pb-5">
           Yenepoya University, a not-for-profit trust, was established in 1991 by entrepreneur Mr. Yenepoya
Abdulla Kunhi as its Chairman, with a commitment of uplifting the minorities in general. From its
inception, the management dreamt of providing quality higher education and hired the best in their
profession towards this goal. The university aims to foster academic excellence and global
competencies among students in diverse domains like management, technology, commerce, etc. It
envisions creating a vibrant knowledge capital and inspiring the leaders of tomorrow who can take
this country to the forefront of developed nations. The National Accreditation and Assessment
Council (NAAC) accredited the university with an “A+” grade in August 2022. </p>
          <p class="paragraph hidden">
           Since the inception of
the NIRF rankings in 2017, the university has consistently been ranked within the top 100 institutions
in the 97th position. Also, the university and its online degree programmes are entitled by the
University Grants Commission (UGC). With the current digital boom and the demand for online
education, Yenepoya aims to take its offerings to the online space, empowering learners worldwide
to become the leaders of tomorrow. Keeping up with these changing times, the university has
successfully launched Yenepoya Online to cater the learners from diverse backgrounds and
communities with an affordable, flexible, and high-quality education preparing them to excel in the
competitive professional world.
          </p>
  
        <p class="flex align-middle place-content-end gap-2">
          <router-link :to="{path: 'about-us'}">
              <button class="readmore-btn text-gray-600">Read More</button><img src="../assets/about/read-more.png" width="25" alt=""></router-link>
            </p>
        </span>
      </div>
    </section>
  </div>
</template>

<script>
import $ from "jquery";
export default {
    mounted() {
            $(".readmore-btn").on("click", function (e) {
      var target = $(e.target).parents(".readmore-section");
      var paragraph = target.find(".paragraph");

      if (paragraph.hasClass("hidden")) {
        paragraph.removeClass("hidden");
        $(this).text("Read Less");
      } else {
        paragraph.addClass("hidden");
        $(this).text("Read More");
      }
    });
    }
}
</script>

<style>


</style>
