<template>
  <section class="max-w-7xl mx-auto p-2 text-center justify-center object-cover lg:flex-row flex">
    <div class="px-4 lg:py-4 py-1 gap-6 justify-between">
      <h2
        class="lg:text-3xl text-[20px] sm:text-[20px] text-gray-700 text-center font-bold lg:py-6 py-4"
      >Career Opportunities</h2>

      <div class="text-left text-justify p-4">
        <p>
          Commerce degree holders will have amazing employment opportunities in India and abroad. The
          field of accounting and finance offers a wide range of opportunities in various industries, including
          corporate, public accounting firms, government agencies, non-profit organizations, and financial
          institutions
        </p>
      </div>

      <div class="px-4 lg:py-8 py-1 gap-6 flex flex-col lg:flex-row mx-0 justify-center">
        <div class="flex lg:w-[50%] w-full">
          <ul class="text-left">
            <li class="flex gap-4 p-2">
              <img src="../../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
              <p>Financial institutions</p>
            </li>
            <li class="flex gap-4 p-2">
              <img src="../../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
              <p>Accountant Financial Analyst</p>
            </li>
            <li class="flex gap-4 p-2">
              <img src="../../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
              <p>Auditor</p>
            </li>
            <li class="flex gap-4 p-2">
              <img src="../../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
              <p>Tax Consultant</p>
            </li>
            <li class="flex gap-4 p-2">
              <img src="../../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
              <p>Financial Planner</p>
            </li>
          </ul>
        </div>

        <div class="flex lg:w-[50%] w-full">
          <ul class="text-left">
            <li class="flex gap-4 p-2">
              <img src="../../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
              <p>Investment Banker</p>
            </li>
            <li class="flex gap-4 p-2">
              <img src="../../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
              <p>Financial Controller</p>
            </li>
            <li class="flex gap-4 p-2">
              <img src="../../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
              <p>Risk Manager</p>
            </li>
            <li class="flex gap-4 p-2">
              <img src="../../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
              <p>Management Accountant</p>
            </li>
            <li class="flex gap-4 p-2">
              <img src="../../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
              <p>Financial Consultant</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style>
</style>