<template>
    <div class="container flex flex-col items-center mt-8"> 
        <h1 class="text-3xl mb-3">404 Error!. The page you are looking for does not exist.</h1>
        <router-link class="lg:text-lg font-semibold text-white bg-[#81C8DC] lg:px-4 py-2 px-1 sm:px-1 sm:text-sm rounded-xl" :to="{name: 'home' }">Go back to Home</router-link>
        </div>
  
</template>

<script>
export default {

}
</script>

<style>

</style>