<template>
  <router-view/>
</template>

<script>

import {computed, reactive } from 'vue';
import { useHead } from '@vueuse/head';

export default ({

  setup() {
    const siteData = reactive({
      title: `Online Degree Program for Under Graduates  | Yenepoya Online`,
      description: `Join Yenepoya Online and equip yourself with an Online Bachelors Degree. Expand horizons through our engaging and flexible digital learning.`,
    })
    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description),
        },
        ],

        link: [
    {
      rel: 'canonical',
      href: 'https://onlineyenepoya.com'
    },
  ],
     
    })
  },
})

</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
