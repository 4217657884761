<template>
  <div>
    <section>
      <div class="bg-[#94C34D]">
        <div class="carousel h-full relative">
          <div class="next absolute top-[40%] right-10 z-[1] cursor-pointer">
            <img src="../assets/right-arrow.png" alt />
          </div>
          <div class="prev absolute top-[40%] left-10 z-[1] cursor-pointer">
            <img src="../assets/left-arrow.png" alt />
          </div>
          <div class="slick h-full">
            <div class="w-full h-full relative">
              <div
                class="flex w-full align-middle overflow-hidden mx-auto place-items-center justify-between"
              >
                <a href="https://apply.onlineyenepoya.com/" target="_blank">
                  <div class="lg:block hidden">
                    <img src="../assets/YO-admission.webp" class="" alt />
                  </div>
                  <div class="lg:hidden">
                    <img
                      src="../assets/YO-admission-mobile.webp"
                      class=""
                      alt
                    /></div
                ></a>
              </div>
            </div>

            <!-- <div class="w-full h-full relative">
              <a href="https://apply.onlineyenepoya.com/" target="_blank">
                <div class="flex max-w-7xl mx-auto place-items-center text-white justify-between">
                  <div class="mx-auto text-center lg:text-left pt-6">
                    <span
                      class="lg:text-[3rem] text-[28px] font-bold lg:leading-[60px] sm:text-[30px] leading-10"
                    >
                    Empowering Minds, 
                      <br />
                      Enabling Futures!
                    </span>
                    <hr class="max-w-[50%] bg-white h-1 rounded-xl my-4 mx-auto lg:mx-0" />
                    <b class>
                      <h1 class="lg:text-2xl text-[1rem] sm:text-[2rem]">
                        Online BBA & Online BCA  <br />programs 
                       launched
                      </h1>
                    </b>
                    <div class="relative sm:block lg:hidden pt-2">
                      <div class="flex justify-center -mr-8">
                        <img src="../assets/banner-2.webp" class="w-[20rem] -ml-8 lg:mr-0" alt />
                      </div>
                      <div
                        class="absolute top-10 -right-14 drop-shadow-2xl py-6 px-6 bg-no-repeat bg-contain banner1"
                      >
                        <img src="../assets/banner/banner-icon1.png" class="mx-auto" />
                        <span class="text-black leading-5">
                          <p>Outcome</p>
                          <p>Based</p>
                          <p>Education</p>
                        </span>
                      </div>
                    
                    </div>

                
                  </div>

                  <div class="relative hidden sm:hidden lg:block">
                    <img src="../assets/banner-2.webp" class="w-[33rem]" alt />
                    <div
                      class="absolute top-10 left-[27rem] drop-shadow-2xl pb-12 pt-6 px-8 bg-no-repeat banner1"
                    >
                      <img src="../assets/banner/banner-icon1.png" alt />
                      <span class="text-black leading-5">
                        <p>Outcome</p>
                        <p>Based</p>
                        <p>Education</p>
                      </span>
                    </div>
                  </div>
                </div>
              </a>
            </div> -->

            <!-- <div class="relative w-full h-full">
              <div class="flex max-w-7xl mx-auto place-items-center text-white justify-between">
                <div class="mx-auto text-center lg:text-left pt-6">
                  <span
                    class="lg:text-[3rem] text-[28px] font-bold lg:leading-[60px] sm:text-[30px] leading-10"
                  >
                    Creating Leaders of
                    <br />Tomorrow
                  </span>
                  <hr class="max-w-[50%] bg-white h-1 rounded-xl my-4 mx-auto lg:mx-0" />
                  <b class>
                    <h1
                      class="lg:text-2xl text-[1rem] sm:text-[1rem]"
                    >YENEPOYA (Deemed to be University)</h1>
                    <p class="lg:text-xl text-lg py-3">Admissions open now</p>
                  </b>
                  <div class="relative sm:block lg:hidden pt-2">
                    <div class="flex justify-center -mr-8">
                      <img
                        src="../assets/banner/banner-girl-element.png"
                        class="w-[18rem] -mr-1 lg:mr-0"
                        alt
                      />
                    </div>
                    <div
                      class="absolute top-16 right-4 drop-shadow-2xl py-6 px-6 bg-no-repeat bg-contain banner1"
                    >
                      <img src="../assets/banner/banner-icon1.png" class="mx-auto" />
                      <span class="text-black leading-5">
                        <p>Outcome</p>
                        <p>Based</p>
                        <p>Education</p>
                      </span>
                    </div>
                    <div
                      class="absolute top-2 -left-1 drop-shadow-2xl px-6 py-6 bg-no-repeat bg-contain banner1"
                    >
                      <img src="../assets/banner/banner-icon2.png" width="50" />
                      <span class="text-black leading-5">
                        <p>ICT</p>
                        <p>Enabled</p>
                      </span>
                    </div>
                  </div>

                  <div class="lg:flex gap-4 justify-start items-center hidden">
                    <div
                      class="relative bg-no-repeat w-[120px] h-[150px] drop-shadow-2xl bg-contain banner"
                    >
                      <div class="absolute left-6 top-5 items-center align-middle">
                        <div class="text-gray-600 text-base">
                          <a href="/programs">Programs</a>
                        </div>
                        <img src="../assets/banner/Achievements.png" alt class="mx-auto my-1 w-12" />
                      
                      </div>
                    </div>

                    <div
                      class="relative bg-no-repeat w-[120px] h-[150px] drop-shadow-2xl bg-contain banner"
                    >
                      <div class="absolute left-5 top-5 items-center align-middle">
                        <div class="text-gray-600 text-base">
                          <a href="/contactus">Admissions</a>
                        </div>
                        <img src="../assets/banner/Admissions.png" alt class="mx-auto my-2 w-12" />
                 
                      </div>
                    </div>
                  </div>
                </div>
                <div class="relative hidden sm:hidden lg:block">
                  <img src="../assets/banner/banner-girl-element.png" class="w-[35rem]" alt />
                  <div
                    class="absolute bottom-48 left-[21.5rem] drop-shadow-2xl pb-12 pt-6 px-8 bg-no-repeat banner1"
                  >
                    <img src="../assets/banner/banner-icon1.png" alt />
                    <span class="text-black leading-5">
                      <p>Outcome</p>
                      <p>Based</p>
                      <p>Education</p>
                    </span>
                  </div>
                  <div
                    class="absolute bottom-[22rem] left-[-2.5rem] drop-shadow-2xl px-10 pt-10 pb-14 bg-no-repeat banner1"
                  >
                    <img src="../assets/banner/banner-icon2.png" width="50" alt />
                    <span class="text-black leading-5">
                      <p>ICT</p>
                      <p>Enabled</p>
                    </span>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="bg-gray-200 lg:hidden py-4">
        <div
          class="flex flex-wrap pl-3 flex-row gap-4 place-content-center justify-center items-center"
        >
          <div
            class="relative bg-no-repeat w-28 h-32 drop-shadow-2xl bg-contain banner"
          >
            <div class="absolute text-center left-5 top-5">
              <div class="text-gray-600 text-[14px]">
                <a href="/programs">Programs</a>
              </div>
              <img
                src="../assets/banner/Achievements.png"
                alt
                class="mx-auto my-2 w-8"
              />
              <!-- <p class="text-center">
                <button class="bg-[#82C8DE] text-xs px-3 py-1 rounded-lg">
                  <a href="/programs">More</a>
                </button>
              </p> -->
            </div>
          </div>

          <div
            class="relative bg-no-repeat w-28 h-32 drop-shadow-2xl bg-contain banner"
          >
            <div class="absolute left-4 top-5">
              <div class="text-gray-600 text-[14px]">
                <a href="/contactus">Admissions</a>
              </div>
              <img
                src="../assets/banner/Admissions.png"
                alt
                class="mx-auto my-2 w-10"
              />
              <!-- <p class="text-center">
                <button class="bg-[#82C8DE] text-xs px-3 py-1 rounded-lg">
                  <a href="/contactus">More</a>
                </button>
              </p> -->
            </div>
          </div>

          <!-- <div class="relative bg-no-repeat w-28 h-32 drop-shadow-2xl bg-contain banner">
            <div class="absolute left-1 top-2">
              <div class="text-gray-600 px-2 text-[14px]">Student Life</div>
              <img src="../assets/banner/Student-Life.png" alt class="mx-auto my-2 w-8" />
              <p class="text-center">
                <button class="bg-[#82C8DE] text-xs px-3 py-1 rounded-lg">More</button>
              </p>
            </div>
          </div>-->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import $ from "jquery";
import "slick-carousel";
export default {
  mounted() {
    this.slickCall();
  },
  methods: {
    slickCall() {
      console.log($(`.slick`), "$(`.slick`)");
      try {
        $(`.slick`).slick({
          infinite: true,
          autoplay: true,
          speed: 200,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          dotsClass: "slick-dots",
          prevArrow: $(`.prev`),
          nextArrow: $(`.next`),
        });
      } catch {
        console.log("error");
      }
    },
  },
};
</script>

<style>
.banner {
  background-image: url(../assets/banner/Eligibility.png);
}

.banner1 {
  background-image: url(../assets/banner/Eligibility.png);
  scale: 60%;
}
</style>
