<template>
  <div>
    <section class="bg-[#95C24D] p-8 hidden lg:block">
      <div class="flex justify-evenly w-full mx-auto max-w-7xl">
        <div class="w-[25%]">
          <a href="/"><img src="../assets/logo.webp" alt class="bg-white p-6 rounded-md" /></a>
        </div>
        <div class="flex w-[75%] justify-evenly gap-3">
          <div class="border-r w-[25%]">
            <div class="text-start">
              <h3 class="text-lg font-bold text-white">YENEPOYA Online</h3>
            </div>
            <div class="pt-3">
              <ul class="flex flex-col gap-3 text-start">
                <li class="text-white text-base">
                  <a href="/about-us">About Us</a>
                </li>
                <li class="text-white text-base">
                  <a href="/blogs">Blogs</a>
                </li>
                
                <!-- <li class="text-white text-base">
                  <a href="#rank">Rankings and Recognitions</a>
                </li> -->
                <li class="text-white text-base">
                  <a href="https://apply.onlineyenepoya.com/" target="_blank" class="">Apply Now</a>
                </li>
              </ul>
            </div>
          </div>

          <div class="w-[25%] border-r">
            <div class="text-start">
              <h3 class="text-lg font-bold text-white">SUPPORT</h3>
            </div>
            <div class="pt-3">
              <ul class="flex flex-col gap-3 text-start">
                <li class="text-white text-base">
                  <a href="/contactus">Contact Us</a>
                </li>
                <li @click="is_widget = true">
                <button class="block text-white" href="../brochure/Yenapoya-overall-brochure.pdf" target="_blank">Download Brochure</button>
                
              </li>
                <!-- <li class="text-white text-base">
                  <a href="https://learn.onlineyenepoya.com/">LMS Login</a>
                </li> -->
              </ul>
            </div>
          </div>
          <div class="w-[25%]">
            <div class="text-start">
              <h3 class="text-lg font-bold text-white">QUICK LINKS</h3>
            </div>
            <div class="pt-3">
              <ul class="flex flex-col gap-3 text-start">
                <li class="text-white text-base">
                  <a href="/disclosures">Disclosures</a>
                </li>
                <li class="text-white text-base">
                  <a href="/academic-corner">Academic Corner</a>
                </li>
                <li class="text-white text-base">
                  <a href="/enrollment-data">Enrollment Data</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-[#635F60] md:block hidden">
      <div class="mx-auto max-w-7xl flex justify-between p-4">
        <p class="text-white text-sm">Copyright © 2023 yenepoya.edu.in All Right Reserved.</p>
        <p class="text-white text-sm">Privacy Policy </p>
      </div>
    </section>

    <section>
      <div class="text-center mx-auto justify-center lg:hidden text-white bg-[#95C24D] p-4 mb-8">
        <div class="justify-center flex mx-auto px-6 pb-4 object-cover items-center">
            <a href="/"><img src="../assets/logo.webp" alt class="bg-white p-6 w-[250px] rounded-md" /></a>
        </div>
        <div id="accordion-collapse" class="" data-accordion="collapse">
          <h2 id="accordion-collapse-heading-12" class="">
            <button
              type="button"
              id="b12"
              class="flex items-center justify-between w-[300px] p-3 font-medium text-center text-white  mx-auto border-b"
              data-accordion-target="#accordion-collapse-body-12"
              aria-expanded="true"
              aria-controls="accordion-collapse-body-12 "
            >
              <p class="text-white font-bold ">YENEPOYA ONLINE</p>
              <img src="../assets/arrow-down.png" alt class="object-contain w-6" />
            </button>
          </h2>
          <div
            id="accordion-collapse-body-12"
            class="hidden  justify-center"
            aria-labelledby="accordion-collapse-heading-12"
          >
            
                <ul class="py-2 text-left text-lg text-white w-[300px] divide-y mx-auto justify-center" aria-labelledby="dropdownDefaultButton">
                <li>
                  <a href="/about-us" class="block px-4 py-2">About Us</a>
                </li>
                <!-- <li>
                  <a href="/programs" class="block px-4 py-2">Online Program</a>
                </li> -->
                <li>
                  <a href="/blogs" class="block px-4 py-2">Blogs</a>
                </li>
                <!-- <li>
                  <a href="#" class="block px-4 py-2">Rankings and recognitions</a>
                </li> -->
                

                <li>
                  <a href="https://apply.onlineyenepoya.com/" target="_blank" class="block px-4 py-2">Apply Now</a>
                </li>
              
              </ul>
            </div>
         

          <h2 id="accordion-collapse-heading-18" class="">
            <button
              type="button"
              id="b18"
              class="flex items-center justify-between w-[300px] p-3 font-medium text-center mx-auto text-white"
              data-accordion-target="#accordion-collapse-body-18"
              aria-expanded="false"
              aria-controls="accordion-collapse-body-4"
            >
              <p class="text-white font-bold">SUPPORT</p>
               <img src="../assets/arrow-down.png" alt class="object-contain w-6" />
            </button>
          </h2>
          <div
            id="accordion-collapse-body-18"
            class="hidden"
            aria-labelledby="accordion-collapse-heading-18"
          >
            <ul class="py-2 text-left text-lg text-white divide-y w-[300px] text-white mx-auto " aria-labelledby="dropdownDefaultButton">
              <li>
                <a href="/contactus" class="block px-4 py-2">Contact Us</a>
              </li>
              <li @click="is_widget = true">
                <button class="block px-4 py-2" href="https://api.onlineyenepoya.com/media/brochure/Yenapoya-overall-brochure.pdf" target="_blank">Download Brochure</button>
                
              </li>
              <!-- <li>
                <a href="https://learn.onlineyenepoya.com/" class="block px-4 py-2"  target="_blank">LMS Login</a>
              </li>
              -->
            </ul>
          </div>

          <h2 id="accordion-collapse-heading-19" class="">
            <button
              type="button"
              id="b18"
              class="flex items-center justify-between w-[300px] p-3 font-medium text-center mx-auto text-white"
              data-accordion-target="#accordion-collapse-body-19"
              aria-expanded="false"
              aria-controls="accordion-collapse-body-4"
            >
              <p class="text-white font-bold">QUICK LINKS</p>
               <img src="../assets/arrow-down.png" alt class="object-contain w-6" />
            </button>
          </h2>
          <div
            id="accordion-collapse-body-19"
            class="hidden"
            aria-labelledby="accordion-collapse-heading-19"
          >
            <ul class="py-2 text-left text-lg text-white divide-y w-[300px] text-white mx-auto " aria-labelledby="dropdownDefaultButton">
              <li>
                <a href="/disclosures" class="block px-4 py-2">Disclosures</a>
              </li>
              <li>
                <a href="/academic-corner" class="block px-4 py-2">Academic Corner</a>
              </li>
             
              <li>
                <a href="/enrollment-data" class="block px-4 py-2"  target="_blank">Enrollment Data</a>
              </li>
             
            </ul>
          </div>

          

          <div class="mx-auto mx-8 p-4 mb-4">
        <p class="text-white text-sm">Copyright © 2023 yenepoya.edu.in All Right Reserved.- <span><a href="#">Privacy Policy</a></span></p>
       
      </div>
        </div>
      </div>
    </section>
    <!--bottom navigation-->
    <section>
      <div
        class="fixed bottom-0 left-0 z-50 w-full h-16 bg-[#635F60] border-t mt-5 border-gray-200 dark:bg-gray-700 dark:border-gray-600 lg:hidden"
      >
        <div class="grid h-full max-w-lg grid-cols-4 mx-auto font-medium">
          <a
            type="button"
            class="inline-flex flex-col items-center justify-center px-5 border-gray-200 border-x hover:bg-[#94C34D] dark:hover:bg-[#94C34D] group dark:border-gray-600"
          >
           <img src="../assets/icos/mobile_hand_icon.webp" class="w-[22px] pt-2" alt="" />
            <span
              class="text-sm text-white group-hover:text-gray-600 dark:group-hover:text-blue-500"
            >Apply</span>
          </a>
         <a href="tel:916361505800"
            type="button" 
            class="inline-flex flex-col items-center justify-center px-5 border-r border-gray-200 hover:bg-[#94C34D] dark:hover:bg-[#94C34D] group dark:border-gray-600"
          >
         
           <img src="../assets/icos/mobile_phone_icon.webp" class="w-[25px] pt-2" alt="" />
            <span
              class="text-sm text-white group-hover:text-gray-600 dark:group-hover:text-blue-500"
            >call</span>
          </a>
          <a
            type="button" href='https://api.whatsapp.com/send/?phone=916361505800&amp;text&amp;app_absent=0' target="_blank"
            class="inline-flex flex-col items-center justify-center px-5 hover:bg-[#94C34D] dark:hover:bg-[#94C34D] group"
          >
          
          
           <img src="../assets/icos/mobile_whatsapp_icon.webp" class="w-[25px] pt-2" alt="" />
           
            <span
              class="text-sm text-white group-hover:text-gray-600 dark:group-hover:text-blue-500"
            >Whatsapp</span>
          </a>
          <a href="/">
          <button
            type="button"
            class="inline-flex flex-col items-center justify-center px-2 border-gray-200 hover:bg-gray-50 dark:hover:bg-gray-800 group border-x dark:border-gray-600"
          >
                <img src="../assets/icos/mobile_council_icon.webp" class="w-[25px] pt-2" alt="" />
            <span
              class="text-sm text-white group-hover:text-gray-600 dark:group-hover:text-blue-500"
            >Counselling</span>
          </button> </a>
        </div>
      </div>
    </section>

    <section v-if="is_widget">

<div  class="fixed inset-0 z-50 overflow-y-auto w-full bg-black bg-opacity-60">
<div class="flex items-center justify-center min-h-screen">
<div class="relative bg-white rounded-lg h-[300px] w-[450px] z-60 p-4">
  <div class="npf_wgts" data-height="344px" :data-w="this.widget_id2"></div>
  <button
    @click="is_widget = false"
    class="absolute top-0 right-1 bg-white rounded-full p-1 z-50"
  >
    <svg
      class="h-6 w-6 fill-current text-gray-700 hover:text-gray-600 transition duration-150 ease-in-out"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <path
        fill-rule="evenodd"
        d="M10 8.586l4.95-4.95 1.414 1.414L11.414 10l4.95 4.95-1.414 1.414L10 11.414l-4.95 4.95-1.414-1.414L8.586 10 3.636 5.05l1.414-1.414L10 8.586z"
        clip-rule="evenodd"
      />
    </svg>
  </button>
 
  <div class>

<div class="flex items-center justify-center">
<div class="relative bg-white rounded-lg shadow-sm">
  <h4 class="font-bold text-lg px-2 py-2">
    Download Brochure
    <br />
  </h4>
  <div >
    <form
      class="space-y-3 p-4 font-normal"
    
      @submit.prevent="brochureSubmit"
    >
      <input
        type="text"
        placeholder="Enter Name *"
        id="FirstName"
        class="p-1 rounded-sm w-full border-gray-300"
        v-model="formInfo.firstName"
      />
      <input
        type="email"
        placeholder="Enter Email Address *"
        id="EmailAddress"
        class="p-1 rounded-sm w-full border-gray-300"
        v-model="formInfo.email"
      />

      <input
      type="tel"
      placeholder="Enter Mobile Number *"
      id="phone" maxlength="10"
      onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
      class="p-1 rounded-sm w-full border-gray-300"
      required
    />
   
      <!-- <select
        name="mx_Program"
        id
        value="mx_Program"
        aria-placeholder="Select Program"
        class="p-1 rounded-sm w-full border-gray-300 text-base hidden"
        required
      >
        <option value="B.Com">B.Com</option>
      </select>
      <select
        name="mx_Elective"
        id="mx_Elective"
        aria-placeholder="Select Elective"
        class="p-1 rounded-sm w-full border-gray-300 text-base hidden"
      >
        <option value="none">Select Specialization *</option>
        <option
          value=" Accounting and Finance"
        > Accounting and Finance</option>
      </select> -->

      <!-- <button
        type="submit"
        class="w-full text-white bg-[#9C9B9D] hover:bg-[#94C34D] rounded-lg px-4 py-2 text-center"
      >Download Brochure &#10148;</button> -->

    
        <button
        type="submit"
        class="w-full text-white bg-[#9C9B9D] hover:bg-[#94C34D] rounded-lg px-4 py-2 text-center"
      >Download Brochure &#10148;</button>
    </form>
  </div>
</div>
</div>

  </div>
</div>
</div>
</div>

</section>
  </div>
</template>

<script>
import axios from "axios";
export default {

  props: ["widget_prop_id"],
    created() {
      this.widget_id = this.widget_prop_id
    },
    data() {
    return {
      is_widget: false,
      showMenu: false,
      formInfo: {
        firstName: "",
        email: "",
        phone: ""
      }
    };
  },

  methods: {
    brochureSubmit(el) {
      console.log("formInput:::", el);
      let dataJson = [
        {
          Attribute: "FirstName",
          Value: el.target.elements.FirstName.value
        },
        {
          Attribute: "EmailAddress",
          Value: el.target.elements.EmailAddress.value
        },
        {
          Attribute: "Phone",
          Value: el.target.elements.phone.value
        },
        // {
        //   Attribute: "mx_Program",
        //   Value: "B.Com"
        // },

        // {
        //   Attribute: "mx_Elective",
        //   Value: " Accounting and Finance"
        // }
      ];
      console.log(dataJson);
      axios
        .post(
          "https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$rca456e8ab1c2865cfece22cda05cbb0c&secretKey=3c0ddb27e7dc562e5c6c08bb8c11b02a9019e041",
          dataJson,
          { cross: true }
        )
        .then(async() => {
          
          await fetch(`https://api.onlineyenepoya.com/media/brochure/Yenapoya-overall-brochure.pdf`)
              .then((response) => response.blob())
              .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const a = document.createElement("a");
                a.href = url;
                a.download = `Yenapoya-overall-brochure.pdf`;
                a.click();
              });
          setTimeout(()=>{
            window.location.href = "/brochure/bba-thankyou";
          },2000)
        })
        .catch((error) => {
          // console.log(error.response.data.ExceptionMessage)
          console.log(error);
        });
    },
  },
   
};
</script>

<style></style>
