<template >
  <div>
<section>
  <div class="flex justify-center">
     <div class=" p-6 lg:mt-12 mt-6 text-center" >
        <h2 class="lg:text-2xl text-[18px] sm:text-[18px] text-center font-bold">SKILLS THAT ENHANCE <span class="text-red-500">YOUR LEARNING CURVE</span></h2>
        <p >
          Become more than just a degree holder with skills that make you stand out from the crowd 
        </p>
     </div>
  </div>

<div class="flex flex-col lg:flex-row justify-center gap-3 lg:gap-4">
<div class="px-4 lg:px-0 flex flex-col wrap items-center gap-3 w-full lg:w-[450px]" >
  
  <div class="w-full" v-for="(item, index) in accordionItems1" :key="index">
    
    <button
      @click="toggleAccordion(index)"
      :class="[
        'accordion-header bg-[#81C8DC] flex justify-between rounded-lg items-center gap-4 w-full lg:w-[450px] p-3 border-b border-black  text-white focus:outline-none',
        { 'active': item.open }
      ]"
    >
   <div> {{ item.title }}</div>
    <img  src="../assets/read-more.png" alt="Logo" class="h-6 object-contain" /> 
     
    

    </button>
    <div v-if="item.open" class="accordion-content w-full lg:w-[450px] bg-white ">
      <!-- <p>{{ item.content }}</p> -->
      <div class="">
        <div v-for="(subItem, subIndex) in item.subItems" :key="subIndex">
          <button
            @click="toggleSubAccordion(index, subIndex)"
            class="sub-accordion-header bg-white flex justify-between  py-1  w-full lg:w-[450px] px-4 border-b border-black  focus:outline-none"
          >
            <div class="p-2">{{ subItem.title }}</div>
            <i class="fa fa-chevron-down p-2"></i>
          </button>
          <div v-if="subItem.open" class="sub-accordion-content w-full lg:w-[450px] border-b   border-black bg-white p-4">
            <p>{{ subItem.content }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<div class="px-4 lg:px-0 w-full lg:w-[450px] flex flex-col wrap items-center gap-3 " >
  
  <div class="w-full"  v-for="(item, index) in accordionItems2" :key="index">
    
    <button
      @click="toggleAccordion2(index)"
      :class="[
        'accordion-header bg-[#81C8DC] flex justify-between rounded-lg items-center gap-4 w-full lg:w-[450px] p-3 border-b border-black  text-white focus:outline-none',
        { 'active': item.open }
      ]"
    >
 
    <div> {{ item.title }}</div>
    <img  src="../assets/read-more.png" alt="Logo" class="h-6 object-contain" /> 
     
    

    </button>
    <div v-if="item.open" class="accordion-content w-full lg:w-[450px] bg-white ">
 
      <div class="">
        <div v-for="(subItem, subIndex) in item.subItems" :key="subIndex">
          <button
            @click="toggleSubAccordion2(index, subIndex)"
            class="sub-accordion-header bg-white flex justify-between  py-1  w-full lg:w-[450px] px-4 border-b border-black  focus:outline-none"
          >
            <div class="p-2">{{ subItem.title }}</div>
            <i class="fa fa-chevron-down p-2"></i>
          </button>
          <div v-if="subItem.open" class="sub-accordion-content w-full lg:w-[450px] border-b   border-black bg-white p-4">
            <p>{{ subItem.content }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
</div>
</section>
</div>
</template>
<script>
export default {
data() {
  return {
    accordionItems1: [
      {
        title: "Problem-Solving and Critical Thinking Skills",
        //content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        open: false,
        subItems: [
          {
            title: "Problem-solving",
            content: " Individuals with problem-solving skills are quick to identify and analyze problems, apply creative solutions, and implement effective strategies to overcome obstacles. Effective problem solvers can think creatively, communicate clearly, analyze critically, and work collaboratively to solve complex challenges. This leads to increased productivity, efficiency, and success in both personal and professional areas.",
            open: false,
          },
          {
            title: "Critical thinking",
            content: "Having critical thinking skills helps an individual to analyze arguments, evaluate information, and make reasoned judgments. It allows them to identify biases, assumptions, and logical fallacies and to weigh evidence systematically and logically. Critical thinking skills can help individuals communicate effectively, make better decisions, and solve problems. ",
            open: false,
          },
          {
            title: "Decision making",
            content: " Having decision-making skills helps an individual to make effective and timely decisions based on available information and analysis. It allows them to weigh the pros and cons, consider potential consequences, and evaluate alternatives before taking action. Additionally, decision-making skills can lead to improved problem-solving abilities, increased confidence, and better outcomes in personal and professional life.",
            open: false,
          },
        ],
      },
      {
        title: "Leadership and Management Skills",
        //content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        open: false,
        subItems: [
          {
            title: "Leadership",
            content: "Individuals possessing leadership skills help individuals inspire and motivate others to work towards a common goal. Influential leaders can think creatively, communicate effectively, delegate tasks, find immediate solutions, and make difficult decisions. They can also foster a positive work environment and encourage personal and professional growth among team members. This can lead to employee satisfaction, increased productivity, and overall success.",
            open: false,
          },
          {
            title: "Strategic thinking",
            content: "Having strong strategic thinking skills can benefit an individual in many ways. It can help them identify opportunities and potential obstacles, anticipate and plan for the future, and make better decisions. With strategic thinking, individuals can develop a clear sense of direction and purpose, prioritize their resources effectively, and align their actions with their goals. It can also help individuals stand out in their careers by demonstrating their ability to think critically and creatively to achieve success.",
            open: false,
          },
        ],
      },
      {
        title: "Emotional Intelligence and Personal Skills",
        //content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        open: false,
        subItems: [
          {
            title: "Emotional Intelligence",
            content: "Emotionally intelligent individuals can better empathize with others and understand their perspectives, emotions, improving interpersonal connections and building teamwork. People possessing emotional intelligence are more capable of managing challenging circumstances, communicating successfully, and adapting to change. ",
            open: false,
          },
          {
            title: "Empathy",
            content: " Individuals who possess empathy are well-versed in understanding and sharing the feelings of others, allowing them to connect on a deeper level and respond rationally and appropriately. It enables them to show compassion, build meaningful relationships, and offer support to others. Additionally, empathy can lead to improved communication, increased emotional intelligence, and better conflict resolution, which are valuable skills in both personal and professional settings.",
            open: false,
          },
          {
            title: "Positive attitude",
            content: "Having a positive attitude can benefit an individual in numerous ways. It can increase their resilience, improve their overall well-being, and boost their self-confidence. Individuals can face challenges optimistically and solve problems more efficiently with a positive mindset. ",
            open: false,
          },
          {
            title: "Self-management",
            content: "Possessing self-management skills can benefit an individual in numerous ways. It can increase their ability to prioritize and meet goals, improve productivity, and enhance overall well-being. With self-management skills, individuals can effectively manage their emotions, time, and resources, leading to greater success in both their personal and professional lives. ",
            open: false,
          },
          {
            title: "Resilience",
            content: " Having resilience can benefit an individual in numerous ways. It can help them navigate challenges positively, bounce back from adversity, and develop coping mechanisms to manage stress. With resilience, individuals can develop greater adaptability and emotional strength leading to improved mental health and well-being. It can also lead to greater success in both personal and professional endeavors by allowing individuals to persevere through setbacks and achieve their goals.",
            open: false,
          },
          {
            title: "Adaptability",
            content: " Individuals having adaptability skills help in numerous ways. It allows them to adjust quickly to new situations, easily overcome challenges and learn new skills. Additionally, it can enhance their career prospects by making them more attractive and valuable to potential employers looking for individuals who can navigate change and uncertainty.",
            open: false,
          },

        ],
      },
      {
        title: " Communication and Interpersonal Skills",
        //content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        open: false,
        subItems: [
          {
            title: "Communication",
            content: "Individuals possessing effective communication skills can improve productivity, build relationships, enhance customer service, resolve conflicts, and boost career growth. Strong communication skills are essential for successful collaboration, preventing misunderstandings, and providing positive experiences for individuals in their personal and professional lives. ",
            open: false,
          },
          {
            title: "Interpersonal communication",
            content: "Having interpersonal communication skills helps individuals to communicate effectively and build relationships with others. It allows them to clearly convey their ideas, thoughts, and feelings and listen actively to the perspectives put out by others. Additionally, interpersonal communication skills can lead to increased empathy, improved collaboration, and better conflict resolution in both personal and professional settings. ",
            open: false,
          },
          {
            title: "Active listening",
            content: "Being an active listener helps an individual to understand others' ideas, perspectives, and emotions. It allows them to pay attention to verbal and nonverbal cues, clarify understanding, and respond appropriately. Additionally, possessing active listening skills can lead to better collaboration, improved relationships, and more effective problem-solving in both personal and professional settings. ",
            open: false,
          },
          {
            title: "Public speaking",
            content: "Having strong public speaking skills can benefit an individual in many ways. It can enhance their communication abilities, boost their confidence, and improve their leadership potential. With public speaking skills, individuals can effectively convey their ideas, build strong connections with their audience, and persuade others. It can also lead to career advancement opportunities and greater success in various fields. ",
            open: false,
          },
        ],
      },
      
    ],
    accordionItems2 : [
      {
        title: "Creativity and Innovation Skills",
        //content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        open: false,
        subItems: [
          {
            title: "Creativity",
            content: " A creative perspective allows individuals to think outside the box and develop original and innovative solutions to problems. It helps individuals find unique perspectives, generate new ideas, and approach tasks with a fresh outlook, leading to increased productivity, efficiency, and success.",
            open: false,
          },
          {
            title: "Design thinking",
            content: " Design thinking helps individuals approach problems and challenges from a user-centered creative perspective. It enables them to define problems, empathize with users, and ideate potential solutions through iteration and testing. Additionally, design thinking can lead to improved user experiences, innovative solutions, and enhanced collaboration in various fields, including business strategy, product design, and social innovation.",
            open: false,
          },
          {
            title: "Curiosity",
            content: "Curiosity is a valuable trait that helps individuals broaden their horizons, learn new things, and spark creativity. With curiosity, individuals can explore different perspectives and ideas, leading to personal growth and a greater understanding of the world around them. It can also instill innovation and problem-solving skills by inspiring individuals to ask questions and seek new solutions. ",
            open: false,
          },
        ],
      },
      {
        title: "Technical and Computer Skills",
        //content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        open: false,
        subItems: [
          {
            title: "Digital literacy",
            content: "An individual may accomplish various tasks on digital devices with the aid of computer literacy, from simple operations to more complex ones. They can use multiple software and web tools to obtain and process information, connect with others, and resolve issues. In the current digital era, computer literacy skills are crucial because they are frequently required in many occupations and can increase productivity and efficiency in both personal and professional life. ",
            open: false,
          },
          {
            title: "Data analysis",
            content: "Having data analysis skills helps individuals to make informed decisions based on facts and evidence rather than assumptions or intuition. It also allows them to identify trends, patterns, and insights that can help increase efficiency, improve processes, and drive business growth. Additionally, data analysis skills are highly valued in today's job market and can lead to career advancement opportunities. ",
            open: false,
          },
        ],
      },
      {
        title: "Industry-Specific Skills",
        //content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        open: false,
        subItems: [
          {
            title: "Writing",
            content: "Writing skills are essential for individuals in personal endeavors and various professions. It helps them to express their ideas with clarity and precision, communicate effectively, and persuade others to take action. Additionally, strong writing skills can enhance an individual's reputation, credibility, and professional image. ",
            open: false,
          },
          {
            title: "Negotiation",
            content: " Having negotiation skills helps an individual to resolve conflicts, reach agreements, and achieve mutually beneficial outcomes. It allows them to effectively communicate their interests, needs, and goals while considering the same for the other party. Additionally, negotiation skills can lead to increased trust, improved relationships, and better outcomes in both personal and professional settings.",
            open: false,
          },
          {
            title: "Personal Branding",
            content: "Having a solid personal brand can benefit an individual in numerous ways. It can build their reputation, increase visibility, and enhance their career prospects. With a personal brand, individuals can establish themselves as experts in their field, differentiate themselves from others, and attract new opportunities. It can also help individuals build a strong network and connect with like-minded individuals with similar values and interests. ",
            open: false,
          },
          {
            title: "Work ethic",
            content: "A strong work ethic helps an individual consistently demonstrate responsibility, dedication, and reliability. It enables them to set goals, prioritize tasks, and work effectively and efficiently to achieve them. A strong work ethic can also increase job satisfaction, productivity, and professional growth and advancement opportunities. ",
            open: false,
          },
          {
            title: "Customer service",
            content: "Having customer service skills helps individuals maintain positive customer relationships and provide excellent service. It allows them to handle complaints, resolve issues, and exceed expectations while maintaining a professional and friendly demeanor. Additionally, customer service skills can improve customer satisfaction, customer retention, and loyalty which are essential for business success. ",
            open: false,
          },
          {
            title: "Professionalism",
            content: " Professionalism helps individuals present themselves respectfully, competently, and ethically, demonstrating their commitment to their colleagues and work. It enables them to maintain high standards, communicate effectively with others, and follow the rules and regulations. Additionally, professionalism can lead to increased trust, credibility, and opportunities for career advancement in various industries.",
            open: false,
          },
        ],
      },
      {
        title: "Time and Project Management Skills",
        content: "",
        open: false,
        subItems: [
          {
            title: "Project management",
            content: "Individuals with project management skills plan, execute and monitor projects efficiently and effectively. Project management involves managing resources, budgets, timelines, and risks while communicating with team members and stakeholders. Strong project management skills can lead to positive outcomes, increased efficiency, and improved organizational performance ",
            open: false,
          },
          {
            title: "Time management",
            content: " Having time management skills helps individuals prioritize tasks, allocate time effectively, and set goals. Time management involves planning and organizing activities, focusing on high-priority tasks, and minimizing distractions. Strong time management skills can increase productivity, reduce stress, and improve work-life balance.",
            open: false,
          },
          {
            title: "Teamwork",
            content: "Individuals who have mastered working in teams collaborate effectively with others and work efficiently to achieve common goals. Effective teamwork involves communication, coordination, cooperation, and mutual respect. Strong teamwork skills can lead to increased efficiency, improved problem-solving, and enhanced overall performance in both personal and professional areas. ",
            open: false,
          },
        ],
      },
    ]
  };
},
methods: {
  toggleAccordion(index) {
    this.accordionItems1.forEach((item, i) => {
      if (i !== index) {
        item.open = false;
      }
    });
    this.accordionItems2.forEach((item) => {
    
        item.open = false;
 
    });
    this.accordionItems1[index].open = !this.accordionItems1[index].open;
  },
  toggleSubAccordion(accordionIndex, subAccordionIndex) {
    this.accordionItems1[accordionIndex].subItems[subAccordionIndex].open = !this.accordionItems1[
      accordionIndex
    ].subItems[subAccordionIndex].open;
  },

  toggleAccordion2(index) {
    this.accordionItems2.forEach((item, i) => {
      if (i !== index) {
        item.open = false;
        this.accordionItems1[index].open = false;
      }
    });
    this.accordionItems1.forEach((item) => {
    
    item.open = false;

});
    this.accordionItems2[index].open = !this.accordionItems2[index].open;
  },
  toggleSubAccordion2(accordionIndex, subAccordionIndex) {
    this.accordionItems2[accordionIndex].subItems[subAccordionIndex].open = !this.accordionItems2[
      accordionIndex
    ].subItems[subAccordionIndex].open;
  },
},
};
</script>

<style>

</style>
