import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
// import store from './store'
import './index.css'
import "slick-carousel/slick/slick.css";
import 'jquery';
import { createHead } from '@vueuse/head';
const head = createHead()
createApp(App).use(head).use(router).mount('#app')
