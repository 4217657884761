<template>
  <div>
    <!-- Specialization & Eligibility -->
    <section
      class="xl:flex lg:gap-12 max-w-7xl mx-auto p-4 text-center lg:flex-row flex flex-col-reverse xl:text-left"
    >
      <div class="lg:w-1/2">
        <h1
          class="lg:text-3xl text-[20px] sm:text-[20px] text-gray-700 font-bold lg:py-6 py-4"
        >Program Highlights</h1>
        <div>
          <ul class="divide-y text-left">
            <li class="flex gap-4 p-2">
              <img src="../../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
              <p>Solid grounding in the fundamentals of Accounting and Finance</p>
            </li>

            <li class="flex gap-4 p-2">
              <img src="../../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
              <p>
                Comprehensive knowledge of specialized subjects such as Forensic
                Accounting, FinTech, IFRS, etc.
              </p>
            </li>

            <li class="flex gap-4 p-2">
              <img src="../../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
              <p>Integration of accounting, finance, and management courses</p>
            </li>

            <li class="flex gap-4 p-2">
              <img src="../../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
              <p>
                Industry-aligned curriculum emphasizing technical expertise and
                professional skills
              </p>
            </li>
          </ul>
        </div>
      </div>

      <div class="border-r m-4 hidden lg:block"></div>
      <div class="lg:w-1/2">
        <h2
          class="lg:text-3xl text-[20px] sm:text-[20px] text-gray-700 font-bold lg:py-6 py-4"
        >Eligibility</h2>
        <ul class="divide-y text-left">
          <li class="flex gap-4 p-2">
            <img src="../../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
            <p>
              Successfully clear 10th and 12th examinations accredited by either State Board, CBSE, NIOS, IGCSE,
              IB, ICSE, recognised by respective State or Central Government
            </p>
          </li>

          <li class="flex gap-4 p-2">
            <img src="../../assets/Programpage/Eligibility/blue_bullet.webp" alt class="h-5" />
            <p>
              Students who are scheduled to take the 10th or 12th examinations are eligible to submit their
              application.
            </p>
          </li>
        </ul>
        <!-- <div class="flex flex-col lg:flex-row">
          <div class="flex py-6 font-semibold flex flex-col lg:flex-row mx-auto justify-center">
           <div class="divide-x flex">
              <div class="eligibility py-6 px-4 flex flex-col items-center gap-2">
                <div>
                  <img src="../../assets/Programpage/Eligibility/icon_1.webp" alt />
                </div>
                <div>
                  <p class="text-center">
                    Online
                    <br />Discussion Forum
                  </p>
                </div>
              </div>

              <div class="eligibility py-6 px-4 flex flex-col items-center gap-2">
                <div>
                  <img src="../../assets/Programpage/Eligibility/icon_2.webp" alt />
                </div>
                <div>
                  <p class="text-center">
                    Pratice
                    <br />Assignments
                  </p>
                </div>
              </div>
           </div>
            
            <div class="border-b border-[#BBBFC2] lg:hidden"></div>
            <div class="border-r border-[#BBBFC2] lg:block hidden" ></div>

              <div class="flex divide-x"> 
            <div class="eligibility py-6 px-4 flex lg:ml-1 ml-[21px] sm:ml-[21px] flex-col items-center gap-2">
              <div>
                <img src="../../assets/Programpage/Eligibility/icon_3.webp" alt />
              </div>
              <div>
                <p class="text-center p-1">
                  Quick
                  <br />Learning book
                </p>
              </div>
            </div>
           
            <div class="eligibility py-6 px-4 flex flex-col items-center gap-2">
              <div>
                <img src="../../assets/Programpage/Eligibility/icon_4.webp" alt />
              </div>
              <div>
                <p class="text-center">
                  Simulated
                  <br />Case Studies
                </p>
              </div>
            </div>
              </div>
          </div>
        </div>-->
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
