<template>
  <div>
    <section class="max-w-7xl mx-auto p-4">
      <h2
        class="text-center lg:text-3xl text-[20px] sm:text-[20px] text-gray-700 font-bold lg:p-4 p-1"
      >About Specialization -  Accounting and Finance</h2>
     

      <div class="text-left lg:text-left readmore-section p-4">
        <p>
          The fields of accounting and finance play a crucial role in streamlining business operations. Pursuing
          an Online B. Com in Accounting and Finance is essential to develop a comprehensive understanding
          of finance and accounting to effectively handle the complexities of any business.
          <br />
          <br />
        </p>
        <p>
          Accounting encompasses various techniques for preparing and managing financial information.
          Financial verticals utilize all available data to optimize performance. On the other hand, finance
          verticals assess the potential for profitability and growth, while accounting encompasses relevant
          techniques to strategize the company&#39;s finances.
          <br />
          <br />
        </p>
        <p class="paragraph hidden">
          Yenepoya Online offers an Online Bachelor of Commerce in Accounting and Finance program that
          provides learners with in-depth knowledge and practical skills in financial management, financial
          accounting, auditing, taxation, and financial analysis.
          <br />
          <br />By combining these two disciplines, individuals can access a wide range of dynamic career
          opportunities and enhance their core skills. The B. Com with Accounting and Finance program offers
          electives in Finance, Accounting, Management, and Economics, which provide a solid foundation for
          various aspects of commerce. Learners can excel in fields such as auditing, financial reporting,
          investment banking, financial analysis, and financial advisory and consulting. Online degree
          programs in India are gaining popularity due to advancements in the virtual world.
        </p>
        <p class="flex align-middle place-content-end gap-2">
          <button class="readmore-btn text-gray-600">Read More</button>
          <img src="../../assets/program/read-more.png" width="25" alt />
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  mounted() {
    $(".readmore-btn").on("click", function(e) {
      var target = $(e.target).parents(".readmore-section");
      var paragraph = target.find(".paragraph");

      if (paragraph.hasClass("hidden")) {
        paragraph.removeClass("hidden");
        $(this).text("Read Less");
      } else {
        paragraph.addClass("hidden");
        $(this).text("Read More");
      }
    });
  }
};
</script>

<style>
p {
  margin-bottom: 15px;
}
</style>