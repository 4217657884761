<template>
  <div>
    <navigation-view />
    <sticky-view />

    <section class="m-auto justify-center items-center">
      <div class="w-full mx-auto relative object-cover bg-center bg-no-repeat">
        <img
          src="../assets/Online-BBA-in-Human-Resource-Management-Desktop.webp"
          class="hidden md:block w-full"
        />
        <img
          src="../assets/online-bba-in-human-resource-management-mobile.webp"
          class="md:hidden"
        />
        <div class="max-w-7xl mx-auto flex place-items-center">
          <div
            class="absolute text-white text-center lg:text-left mx-auto lg:top-[18%] -top-6"
          >
            <div class="lg:ml-[14%]">
              <h1
                class="lg:text-4xl lg:pt-2 pt-12 py-2 font-extrabold text-[20px] sm:text-[20px]"
              >
                BBA with Specialization in <br />General Management
              </h1>

              <h2
                class="text-white text-lg w-[80%] lg:text-left text-center md:ml-0 m-auto"
              >
                Transform Organizations, Elevate Careers with our Online BBA in
                General Management
              </h2>
            </div>
            <div class="flex mt-6">
              <div class="w-full h-[1px] bg-gradient-to-l from-white"></div>
              <div class="w-full h-[1px] bg-gradient-to-r from-white"></div>
            </div>
            <div
              class="flex flex-row text-sm bg-gradient-to-r from-[#7ab422] from-0% via-[#72a821] via-80% to-90% to-[#7ab422] md:text-base lg:ml-32 lg:mr-52"
            >
              <div
                class="w-[40%] flex justify-center py-2 border-white border-r"
              >
                <span class="flex items-center text-lg font-bold md:gap-2">
                  <img
                    src="@/assets/Programpage/banner/banner-3-Years.png"
                    class="mr-2"
                    width="30"
                    alt
                  />
                  3 Years
                </span>
              </div>
              <div class="w-[60%] flex justify-center py-2">
                <span class="flex items-center text-lg font-bold md:gap-2">
                  <img
                    src="@/assets/Programpage/banner/banner-Total-Credits--140.png"
                    class="mr-2"
                    width="30"
                    alt
                  />
                  Live Online Classes
                </span>
              </div>
            </div>
            <div class="flex mb-6">
              <div class="w-full h-[1px] bg-gradient-to-l from-white"></div>
              <div class="w-full h-[1px] bg-gradient-to-r from-white"></div>
            </div>
            <div
              class="flex gap-4 place-content-center lg:place-content-start mb-4 lg:ml-32"
            >
              <a
                href="https://apply.onlineyenepoya.com/"
                target="_blank"
                class="px-6 py-2 bg-[#82C8DE] font-bold rounded-lg shadow-xl"
                >Apply Now</a
              >

              <!-- <div @click="is_widget = true">
                <button
                  class="px-6 py-2 bg-[#82C8DE] font-bold rounded-lg shadow-xl"
                  href="https://api.onlineyenepoya.com/media/brochure/Yenepoya_BBA-HRM.pdf"
                  target="_blank"
                >
                  Download Brochure
                </button>
              </div> -->
            </div>
          </div>
        </div>
        <div v-if="is_widget" class>
          <downpopView
            @closeWidget="is_widget = false"
            :widget_prop_id="this.brochure_widget"
          />
        </div>
      </div>
    </section>

    <section class="max-w-7xl mx-auto p-4">
      <h2
        class="text-center lg:text-3xl text-[20px] sm:text-[20px] text-gray-700 font-bold lg:p-4 p-1"
      >
        About Specialization - General Management
      </h2>

      <!-- <h6
        class="text-center lg:text-xl text-[20px] sm:text-[20px] text-gray-700"
      >International Finance and Accounting</h6>-->
      <div class="text-left text-justify readmore-section p-4">
        <p>
          Management is the backbone of any successful organization, driving
          strategic decision-making, efficient operations, and sustainable
          growth. An Online BBA in General Management is meticulously crafted to
          equip you with the foundational skills needed to lead and manage
          across diverse industries and sectors.
        </p>
        <p>
          This program offers a dynamic curriculum that delves into key
          subjects, including Marketing, Finance, Human Resource Management, and
          more, ensuring you comprehensively understand the multifaceted
          business landscape and its demands. By immersing yourself in this
          comprehensive curriculum, you'll develop a versatile skill set that
          enables you to navigate complex business challenges with confidence
          and proficiency.
        </p>
        <p class="paragraph hidden">
          Upon completion of the Online BBA General Management Program, you'll
          emerge as a well-rounded professional with the strategic vision,
          analytical acumen, and leadership skills necessary to thrive in a
          competitive business environment. Graduates can pursue diverse career
          opportunities across industries, including roles in management
          consulting, project management, operations management, marketing
          management, human resources, business development, and
          entrepreneurship.
        </p>

        <p class="flex align-middle place-content-end gap-2">
          <button class="readmore-btn text-gray-600">Read More</button>
          <img src="../assets/program/read-more.png" width="25" alt />
        </p>
      </div>
    </section>

    <section
      class="xl:flex lg:gap-12 max-w-7xl mx-auto p-4 text-center lg:flex-row flex flex-col-reverse xl:text-left"
    >
      <div class="lg:w-1/2">
        <h1
          class="lg:text-3xl text-[20px] sm:text-[20px] text-gray-700 font-bold lg:py-6 py-4"
        >
          Program Highlights
        </h1>
        <div>
          <ul class="divide-y text-left">
            <li class="flex gap-4 p-2">
              <img
                src="../assets/Programpage/Eligibility/blue_bullet.webp"
                alt
                class="h-5"
              />
              <p>
                Explore essential marketing principles to enhance business
                strategies.
              </p>
            </li>

            <li class="flex gap-4 p-2">
              <img
                src="../assets/Programpage/Eligibility/blue_bullet.webp"
                alt
                class="h-5"
              />
              <p>
                Learn fundamental project management skills for successful
                project execution
              </p>
            </li>

            <li class="flex gap-4 p-2">
              <img
                src="../assets/Programpage/Eligibility/blue_bullet.webp"
                alt
                class="h-5"
              />
              <p>
                Understand human behavior in organizations for better teamwork.
              </p>
            </li>

            <li class="flex gap-4 p-2">
              <img
                src="../assets/Programpage/Eligibility/blue_bullet.webp"
                alt
                class="h-5"
              />
              <p>
                Develop proficiency in financial planning and analysis
                techniques.
              </p>
            </li>
          </ul>
        </div>
      </div>

      <div class="border-r m-4 hidden lg:block"></div>
      <div class="lg:w-1/2">
        <h2
          class="lg:text-3xl text-[20px] sm:text-[20px] text-gray-700 font-bold lg:py-6 py-4"
        >
          Eligibility
        </h2>
        <ul class="divide-y text-left">
          <li class="flex gap-4 p-2">
            <img
              src="../assets/Programpage/Eligibility/blue_bullet.webp"
              alt
              class="h-5"
            />
            <p>
              Successfully clear 10th and 12th examinations accredited by either
              State Board, CBSE, NIOS, IGCSE, IB, ICSE, recognized by respective
              State or Central Government students who are scheduled to take the
              10th or 12th examinations are eligible to submit their
              application.
            </p>
          </li>
        </ul>
      </div>
    </section>

    <section
      class="max-w-7xl mx-auto p-2 text-center justify-center object-cover lg:flex-row flex"
    >
      <div class="px-4 lg:py-4 py-1 gap-6 justify-between">
        <h2
          class="lg:text-3xl text-[20px] sm:text-[20px] text-gray-700 text-center font-bold lg:py-6 py-4"
        >
          Career Opportunities
        </h2>

        <div class="text-left text-justify p-4">
          <p>
            The General Management field presents many career opportunities
            across diverse industries, ranging from automotive and hospitality
            to fashion, food and beverage, entertainment, finance, education,
            and beyond.
          </p>
        </div>

        <div
          class="px-4 lg:py-8 py-1 gap-6 flex flex-col lg:flex-row mx-0 justify-center"
        >
          <div class="flex lg:w-[50%] w-full">
            <ul class="text-left">
              <li class="flex gap-4 p-2">
                <img
                  src="../assets/Programpage/Eligibility/blue_bullet.webp"
                  alt
                  class="h-5"
                />
                <p>Management Consultant</p>
              </li>
              <li class="flex gap-4 p-2">
                <img
                  src="../assets/Programpage/Eligibility/blue_bullet.webp"
                  alt
                  class="h-5"
                />
                <p>Project Manager</p>
              </li>
              <li class="flex gap-4 p-2">
                <img
                  src="../assets/Programpage/Eligibility/blue_bullet.webp"
                  alt
                  class="h-5"
                />
                <p>Operations Manager</p>
              </li>
              <li class="flex gap-4 p-2">
                <img
                  src="../assets/Programpage/Eligibility/blue_bullet.webp"
                  alt
                  class="h-5"
                />
                <p>Marketing Manager</p>
              </li>
              <li class="flex gap-4 p-2">
                <img
                  src="../assets/Programpage/Eligibility/blue_bullet.webp"
                  alt
                  class="h-5"
                />
                <p>Human Resources Specialist</p>
              </li>
            </ul>
          </div>

          <div class="flex lg:w-[80%] w-full">
            <ul class="text-left">
              <li class="flex gap-4 p-2">
                <img
                  src="../assets/Programpage/Eligibility/blue_bullet.webp"
                  alt
                  class="h-5"
                />
                <p>Business Development Manager</p>
              </li>
              <li class="flex gap-4 p-2">
                <img
                  src="../assets/Programpage/Eligibility/blue_bullet.webp"
                  alt
                  class="h-5"
                />
                <p>Entrepreneur</p>
              </li>
              <li class="flex gap-4 p-2">
                <img
                  src="../assets/Programpage/Eligibility/blue_bullet.webp"
                  alt
                  class="h-5"
                />
                <p>Sales Manager</p>
              </li>
              <li class="flex gap-4 p-2">
                <img
                  src="../assets/Programpage/Eligibility/blue_bullet.webp"
                  alt
                  class="h-5"
                />
                <p>Strategic Planner</p>
              </li>
              <li class="flex gap-4 p-2">
                <img
                  src="../assets/Programpage/Eligibility/blue_bullet.webp"
                  alt
                  class="h-5"
                />
                <p>Supply Chain Manager</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section class="p-4 lg:py-8 py-2 max-w-7xl mx-auto">
      <h2
        class="lg:text-3xl text-[20px] sm:text-[20px] text-gray-700 font-bold text-center lg:p-4 p-0"
      >
        Modules
      </h2>
      <div class="lg:flex lg:gap-4">
        <div>
          <div data-accordion="collapse" class>
            <h2 id="accordion-collapse-heading-7" class="pt-4 moreBox1">
              <button
                class="flex items-center justify-between w-80 md:w-[480px] xl:w-[600px] mx-auto p-5 border border-gray-200 rounded-xl bg-white dark:bg-white"
                id="b6"
                data-accordion-target="#accordion-collapse-body-7"
                aria-expanded="false"
                aria-controls="accordion-collapse-body-7"
              >
                <span class="text-black">Semester 1 (24 Credits)</span>
                <img src="../assets/about/read-more.png" width="20" alt />
              </button>
            </h2>
            <div
              id="accordion-collapse-body-7"
              class="hidden"
              aria-labelledby="accordion-collapse-heading-7"
            >
              <div
                class="p-5 border border-t-0 border-gray-200 w-80 md:w-[480px] xl:w-[600px] mx-auto rounded-b-xl bg-white"
              >
                <ul class="divide-y">
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p>English</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p>Business Management</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p>Accounting for Business</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p>Modern Business Environment</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p>Business Regulatory Framework</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p>Quantitative Techniques for Management</p>
                  </li>
                </ul>
              </div>
            </div>
            <h2 id="accordion-collapse-heading-8" class="pt-4 moreBox1">
              <button
                class="flex items-center justify-between w-80 md:w-[480px] xl:w-[600px] mx-auto p-5 border border-gray-200 rounded-xl bg-white dark:bg-white"
                id="b7"
                data-accordion-target="#accordion-collapse-body-8"
                aria-expanded="false"
                aria-controls="accordion-collapse-body-8"
              >
                <span class="text-black">Semester 2 (24 Credits)</span>
                <img src="../assets/about/read-more.png" width="20" alt />
              </button>
            </h2>
            <div
              id="accordion-collapse-body-8"
              class="hidden"
              aria-labelledby="accordion-collapse-heading-8"
            >
              <div
                class="p-5 border border-t-0 border-gray-200 w-80 md:w-[480px] xl:w-[600px] mx-auto rounded-b-xl bg-white"
              >
                <ul class="divide-y">
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p>Marketing Management</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p>Organization Behavior and HRM</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p>Cost and Management Accounting</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p>Corporate Finance</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p>Introduction to Business Analytics</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p>Communicative English</p>
                  </li>
                </ul>
              </div>
            </div>
            <h2 id="accordion-collapse-heading-9" class="pt-4 moreBox1">
              <button
                class="flex items-center justify-between w-80 md:w-[480px] xl:w-[600px] mx-auto p-5 border border-gray-200 rounded-xl bg-white dark:bg-white"
                id="b8"
                data-accordion-target="#accordion-collapse-body-9"
                aria-expanded="false"
                aria-controls="accordion-collapse-body-9"
              >
                <span class="text-black">Semester 3 (20 Credits)</span>
                <img src="../assets/about/read-more.png" width="20" alt />
              </button>
            </h2>
            <div
              id="accordion-collapse-body-9"
              class="hidden"
              aria-labelledby="accordion-collapse-heading-9"
            >
              <div
                class="p-5 border border-t-0 border-gray-200 w-80 md:w-[480px] xl:w-[600px] mx-auto rounded-b-xl bg-white"
              >
                <ul class="divide-y">
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p>Managerial Economics</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p>Organizational Change and Development</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p>Workforce Planning</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p>Technology for Business</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p>Environmental Science</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div data-accordion="collapse">
            <h2
              id="accordion-collapse-heading-10"
              class="pt-4 moreBox1 lg:block hidden"
            >
              <button
                class="flex items-center justify-between w-80 md:w-[480px] xl:w-[600px] mx-auto p-5 border border-gray-200 rounded-xl bg-white dark:bg-white"
                id="b9"
                data-accordion-target="#accordion-collapse-body-10"
                aria-expanded="false"
                aria-controls="accordion-collapse-body-10"
              >
                <span class="text-black">Semester 4 (20 Credits)</span>
                <img src="../assets/about/read-more.png" width="20" alt />
              </button>
            </h2>
            <div
              id="accordion-collapse-body-10"
              class="hidden"
              aria-labelledby="accordion-collapse-heading-10"
            >
              <div
                class="p-5 border border-t-0 border-gray-200 w-80 md:w-[480px] xl:w-[600px] mx-auto rounded-b-xl bg-white"
              >
                <ul class="divide-y">
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p>Macroeconomics</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p>Financial Markets and Instruments</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p>Training and Development</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p>Compensation Management</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p>Entrepreneurship</p>
                  </li>
                </ul>
              </div>
            </div>
            <h2
              id="accordion-collapse-heading-11"
              class="pt-4 moreBox1 lg:block hidden"
            >
              <button
                class="flex items-center justify-between w-80 md:w-[480px] xl:w-[600px] mx-auto p-5 border border-gray-200 rounded-xl bg-white dark:bg-white"
                id="b10"
                data-accordion-target="#accordion-collapse-body-11"
                aria-expanded="false"
                aria-controls="accordion-collapse-body-11"
              >
                <span class="text-black">Semester 5 (20 Credits)</span>
                <img src="../assets/about/read-more.png" width="20" alt />
              </button>
            </h2>
            <div
              id="accordion-collapse-body-11"
              class="hidden"
              aria-labelledby="accordion-collapse-heading-11"
            >
              <div
                class="p-5 border border-t-0 border-gray-200 w-80 md:w-[480px] xl:w-[600px] mx-auto rounded-b-xl bg-white"
              >
                <ul class="divide-y">
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p>Strategic Leadership</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p>Performance Management System</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p>Fundamentals of HR Analytics</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p>Research Methodology</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p>Personal and Managerial Effectiveness</p>
                  </li>
                </ul>
              </div>
            </div>
            <h2
              id="accordion-collapse-heading-18"
              class="pt-4 moreBox1 lg:block hidden"
            >
              <button
                class="flex items-center justify-between w-80 md:w-[480px] xl:w-[600px] mx-auto p-5 border border-gray-200 rounded-xl bg-white dark:bg-white"
                id="b11"
                data-accordion-target="#accordion-collapse-body-18"
                aria-expanded="false"
                aria-controls="accordion-collapse-body-18"
              >
                <span class="text-black">Semester 6 (24 Credits)</span>
                <img src="../assets/about/read-more.png" width="20" alt />
              </button>
            </h2>
            <div
              id="accordion-collapse-body-18"
              class="hidden"
              aria-labelledby="accordion-collapse-heading-18"
            >
              <div
                class="p-5 border border-t-0 border-gray-200 w-80 md:w-[480px] xl:w-[600px] mx-auto rounded-b-xl bg-white"
              >
                <ul class="divide-y">
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p>Operations Management</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p>Strategic Management</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p>International HRM</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p>Information Systems for Business</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p>Ethics and Values</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p>Research Project*</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-center mt-4">
          <div
            class="flex align-middle place-content-end gap-2 lg:hidden"
            id="loadMore1"
          >
            <button class="readmore-btn text-gray-600">
              <a href="#">View More</a>
            </button>
            <img src="../assets/program/read-more.png" width="25" alt />
          </div>
        </div>
      </div>
    </section>
    <section class="max-w-7xl mx-auto lg:py-6 py-2">
      <h1
        class="text-3xl text-gray-700 text-center font-bold p-2 lg:text-3xl text-[20px] sm:text-[20px]"
      >
        Fee Structure
      </h1>
      <div
        class="mx-auto bg-white rounded-lg shadow-lg text-center p-2 lg:w-full w-[90%] sm:w-[90%]"
      >
        <div class="m-4">
          <!-- Tab content -->
          <div class="tabcontent flex flex-col lg:flex-row">
            <div class="w-full">
              <h1
                class="tablinks align-bottom rounded-t-lg py-4 mt-4 px-4 mx-1 text-white font-semibold text-center bg-[#9D9B9C]"
              >
                Domestic fee structure
              </h1>
              <table class="w-full border-4 border-white">
                <tr class="bg-[#94C34D] text-white h-16">
                  <th class="border-4 border-white">Yearly Fee Plan</th>
                  <th class="border-4 border-white">Total Year Fee Plan</th>
                </tr>
                <tr class="bg-[#BDE6F7] h-10 text-center">
                  <td class="border-4 border-white">₹ 40,000</td>
                  <td class="border-4 border-white">₹ 1,20,000</td>
                </tr>
              </table>
            </div>
            <div class="w-full">
              <h1
                class="tablinks align-bottom rounded-t-lg py-4 mt-4 px-4 mx-1 text-white font-semibold text-center bg-[#9D9B9C]"
              >
                International fee structure
              </h1>
              <table class="w-full border-4 border-white">
                <tr class="bg-[#94C34D] text-white h-16">
                  <!-- <th class="border-4 border-white">Semester x 6</th> -->
                  <th class="border-4 border-white">Yearly Fee Plan</th>
                  <th class="border-4 border-white">Total Year Fee Plan</th>
                </tr>
                <tr class="bg-[#BDE6F7] h-10 text-center">
                  <td class="border-4 border-white">$ 700</td>
                  <td class="border-4 border-white">$ 2,100</td>
                  <!-- <td class="border-4 border-white">$ 2,400</td> -->
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
    <faq-view />
    <footer-view />
  </div>
</template>

<script>
import $ from "jquery";
import FaqView from "../components/FaqView.vue";
import FooterView from "../components/FooterView.vue";
import NavigationView from "../components/NavigationView.vue";
import StickyView from "../components/StickyView.vue";
import DownpopView from "@/components/DownpopView.vue";
import { useHead } from "@vueuse/head";
export default {
  components: { FaqView, StickyView, FooterView, NavigationView, DownpopView },
  data() {
    return {
      is_widget: false,
      showMenu: false,
      openTab: 1,
    };
  },
  // created(){
  //   setTimeout(()=>{
  //     this.is_widget = true
  //   },2000)
  // },

  mounted() {
    $(".readmore-btn").on("click", function (e) {
      var target = $(e.target).parents(".readmore-section");
      var paragraph = target.find(".paragraph");

      if (paragraph.hasClass("hidden")) {
        paragraph.removeClass("hidden");
        $(this).text("Read Less");
      } else {
        paragraph.addClass("hidden");
        $(this).text("Read More");
      }
    });

    $(document).ready(function () {
      $(".moreBox1").slice(0, 3).show();
      if ($(".accordion-item:hidden").length != 0) {
        $("#loadMore1").show();
      }
      $("#loadMore1").on("click", function (e) {
        e.preventDefault();
        $(".moreBox1:hidden").slice(0, 4).slideDown();
        if ($(".moreBox1:hidden").length == 0) {
          $("#loadMore1").fadeOut("slow");
        }
      });
    });
  },

  setup() {
    useHead({
      // Can be static or computed
      title: "Online BBA in Human Resource Management | Yenepoya Online",
      meta: [
        {
          name: `description`,
          content:
            "Experience growth and success in HR Domain with an Online BBA in Human Resource Management from Yenepoya Online. Start your journey to success",
        },
      ],

      link: [
        {
          rel: "canonical",
          href: "https://onlineyenepoya.com/programs/online-bba-human-resource-management",
        },
      ],
    });
  },
};
</script>

<style></style>
