<template>
    <div class="">
        <div id="lsq-portal-widget-fd4f5bf5-1a45-11ee-8d02-062290c66066-270408ea-54b4-444b-a320-5a835450a80f"
                class="lsq-portal-widget lsq-form-widget">
    </div>
    </div>
  
</template>

<script>
export default{
    mounted() {
      let lsqScript = document.createElement('script')
      lsqScript.setAttribute('src', 'https://portal-widgets.lsqportal.com/assets/bootstrap-widget.js')
      lsqScript.setAttribute('data-widget-id', 'fd4f5bf5-1a45-11ee-8d02-062290c66066')
      lsqScript.setAttribute('data-version-id', '270408ea-54b4-444b-a320-5a835450a80f')
      lsqScript.setAttribute('data-formjs', 'https://dhx9mmhpfsala.cloudfront.net/cdn/externalforms/r21/js/lsq.form.js')
      lsqScript.setAttribute('data-URL', 'https://portal-widgets.lsqportal.com')
      lsqScript.setAttribute('charset', 'utf-8')
      lsqScript.addEventListener('load', (event) => {
        window['___lsq-portal-widget-helpers___'].bootstrapLSQWidget(event)
});
      let container = document.getElementById('lsq-portal-widget-fd4f5bf5-1a45-11ee-8d02-062290c66066-270408ea-54b4-444b-a320-5a835450a80f')
      container.appendChild(lsqScript)

    },

}

</script>

