<template>
  <div>
   
    <section class="bg-[#95C24D] ">
      <div class="mx-auto max-w-7xl flex lg:flex-row flex-col justify-between p-4 lg:flex hidden">
        <p class="text-white text-sm">Copyright © 2017 yenepoya.edu.in All Right Reserved.</p>
        <p class="text-white text-sm">Privacy Policy </p>
      </div>
        <div class="text-center lg:hidden text-white bg-[#95C24D] p-4 mb-8">
          <div class="mx-auto mx-8 p-4">
        <p class="text-white text-sm">Copyright © 2017 yenepoya.edu.in All Right Reserved.- <span><a href="#">Privacy Policy</a></span></p>
       
      </div>
        </div>
    </section>

    <!--bottom navigation-->
    <section>
      <div
        class="fixed bottom-0 left-0 z-50 w-full h-16 bg-[#635F60] border-t mt-5 border-gray-200 dark:bg-gray-700 dark:border-gray-600 lg:hidden sm:block"
      >
        <div class="grid h-full max-w-lg grid-cols-4 mx-auto font-medium">
          <button
            type="button"
            class="inline-flex flex-col items-center justify-center px-5 border-gray-200 border-x hover:bg-gray-50 dark:hover:bg-gray-800 group dark:border-gray-600"
          >
           <img src="../assets/icos/mobile_hand_icon.webp" class="w-[22px] pt-2" alt="" />
            <span
              class="text-sm text-white group-hover:text-gray-600 dark:group-hover:text-blue-500"
            >Apply</span>
          </button>
          <button
            type="button"
            class="inline-flex flex-col items-center justify-center px-5 border-r border-gray-200 hover:bg-gray-50 dark:hover:bg-gray-800 group dark:border-gray-600"
          >
           <img src="../assets/icos/mobile_phone_icon.webp" class="w-[25px] pt-2" alt="" />
            <span
              class="text-sm text-white group-hover:text-gray-600 dark:group-hover:text-blue-500"
            >call</span>
          </button>
          <button
            type="button"
            class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group"
          >
           <img src="../assets/icos/mobile_whatsapp_icon.webp" class="w-[25px] pt-2" alt="" />
            <span
              class="text-sm text-white group-hover:text-gray-600 dark:group-hover:text-blue-500"
            >Whatsapp</span>
          </button>
          <a href="/">
          <button
            type="button"
            class="inline-flex flex-col items-center justify-center px-2 border-gray-200 hover:bg-gray-50 dark:hover:bg-gray-800 group border-x dark:border-gray-600"
          >
                <img src="../assets/icos/mobile_council_icon.webp" class="w-[25px] pt-2" alt="" />
            <span
              class="text-sm text-white group-hover:text-gray-600 dark:group-hover:text-blue-500"
            >Counselling</span>
          </button> </a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
