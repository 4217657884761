<template>
<div class="program">
    <navigation-view/>
    <pbanner-view/>
    <abtpro-view/>
    <special-view/>
    <career-view/>
    <module-view/>
    <sticky-view />
    <!-- <faculty-view/> -->
    <fee-view/>
    <faq-view/>
    <footer-view/>
</div>
</template>


<script>

import FaqView from '../components/FaqView.vue'
import FooterView from '../components/FooterView.vue'
import NavigationView from '../components/NavigationView.vue'
import AbtproView from '../components/program/AbtproView.vue'
import FeeView from '../components/program/FeeView.vue'
import ModuleView from '../components/program/ModuleView.vue'
import PbannerView from '../components/program/PbannerView.vue'
import SpecialView from '../components/program/SpecialView.vue'
import StickyView from '../components/StickyView.vue'
import CareerView from '../components/program/CareerView.vue'
import { useHead } from '@vueuse/head'

export default {
  components: {
    NavigationView,
    PbannerView,
    AbtproView,
    SpecialView,
    ModuleView,
    StickyView,
    CareerView,
    FaqView,
    FeeView,
    FooterView,
  },

  setup() {

useHead({
  // Can be static or computed
  title: 'Online B.Com Accounting and Finance | Yenepoya Online',
  meta: [
    {
      name: `description`,
      content: 'Empower yourself with sector-specific skills through our Online B.Com in Accounting and Finance from Yenepoya Online all at your convenience.',
    },
    ],

    link: [
    {
      rel: 'canonical',
      href: 'https://onlineyenepoya.com/programs/online-bcom-accounting-and-finance'
    },
  ],
 
})
},

}
</script>


