<template>
  <div>
    <nav-view />
    <sticky-view />
     <section class="bg-[#94C34D]">
        <div class="max-w-7xl  mx-auto flex  flex-row place-items-center">
            <div class="text-white lg:w-[33%] w-full text-center lg:text-left mx-auto w-auto xl:w-auto">
                <div class="">
                <h1 class="lg:text-4xl pt-8 py-8 font-extrabold text-[30px] sm:text-[30px]">Online B.Com</h1>
                 <div class="flex flex-row  lg:flex-col w-full text-sm md:text-base text-left lg:divide-y lg:block hidden">
                     <div class="lg:w-full flex lg:justify-start py-2">
                        <span class="flex lg:items-start items-center text-lg md:gap-2"><img src="../assets/landing/banner-3-Years.png" class="mr-2" width="30" alt=""> 3 In-Demand Electives</span>                        
                    </div>
                    <div class="lg:w-full flex lg:justify-start py-2">
                        <span class="flex lg:items-start items-center text-lg md:gap-2"><img src="../assets/landing/banner-Total-Credits--140.png" class="mr-2" width="30" alt=""> Live Online Sessions</span>                        
                    </div>
                    <div class="lg:w-full flex justify-start py-2">
                        <span class="flex lg:items-start items-center text-lg md:gap-2"><img src="../assets/landing/banner-Live-Online-Classes.png" class="mr-2" width="30" alt=""> 3 Years (6 Sem)</span>                        
                    </div>
                </div>

                 <div class="flex flex-row  lg:flex-col w-full text-sm md:text-base text-left divide-x lg:hidden">
                    <div class="lg:w-full px-2 flex flex-col justify-center items-center lg:justify-start py-2">
                        <img src="../assets/landing/banner-3-Years.png" class="pb-3 object-contain" width="30" alt="">
                        <div class="lg:flex lg:items-start items-center text-[14px] md:gap-2 text-center"> 3 In-Demand Electives</div>                        
                    </div>
                     <div class="lg:w-full px-2 flex flex-col justify-center items-center lg:justify-start py-2">
                        <img src="../assets/landing/banner-Total-Credits--140.png" class="pb-3 object-contain" width="30" alt="">
                        <div class="lg:flex lg:items-start items-center text-[14px] md:gap-2 text-center"> Live Online Sessions</div>                        
                    </div>
                      <div class="lg:w-full px-2 flex flex-col justify-center items-center lg:justify-start py-2">
                        <img src="../assets/landing/banner-Live-Online-Classes.png" class="pb-3 object-contain" width="30" alt=""> 
                         <div class="lg:flex lg:items-start items-center text-[14px] md:gap-2 text-center"> 3 Years (6 Sem)</div>                        
                    </div>
                </div>


                <div class="flex pt-8 pb-4  place-content-center lg:place-content-start mb-4">
                    <a class="px-6 py-2 bg-[#82C8DE] font-bold rounded-lg shadow-xl" href="brochure/BCom-in-Accounting-and-Finance.pdf"> Download Brochure</a>
                </div>
                </div>
         <div class="flex flex-col px-4 lg:hidden ">
    <h2 class="lg:text-3xl  py-2 text-white font-extrabold text-[30px] sm:text-[30px] clear-both">Thank You</h2>
    <p class="pb-8">Your Enquiry has been submitted successfully. Our admissions team will get in touch with you soon. A verification email has been sent to your registered email ID.
Please ignore if already verified.</p>


  </div> 
                 <div class="lg:hidden flex justify-center">
                <img src="../assets/landing/banner_girl.webp" width="" alt="" class="object-contain w-[80%]">
            </div>

    

      
            </div>
            <div class="hidden lg:block relative lg:w-[33%]">
                <div class="w-full bottom-0">
                <img src="../assets/landing/banner_girl.webp" width="" alt=""></div>
            </div>
  <div class="flex  lg:w-[33%] border-1 p-4 hidden lg:block"> 
   <h2 class="lg:text-3xl pt-2 py-2 text-white font-extrabold text-[30px] sm:text-[30px]">Thank You</h2>
   <p class="">Your Enquiry has been submitted successfully. Our admissions team will get in touch with you soon. A verification email has been sent to your registered email ID.
Please ignore if already verified.</p>

  </div> 
   
        </div>
  </section>
   <ranking-view/>
   <program-view/>

    <section class="max-w-7xl mx-auto p-2 text-center justify-center object-cover lg:flex-row flex">
      <div class="px-4 py-4 gap-6 justify-between">
        <h2 class=" lg:text-3xl text-[20px] sm:text-[20px] text-gray-700 text-center font-bold lg:py-6 py-4">Why Choose Yenepoya Online</h2>
        <div class="px-4 py-8 gap-6 flex flex-col lg:flex-row">
        <div class="flex">
          <ul class="text-left">
            <li class="flex gap-4 p-2">
              <img src="../assets/landing/why_choose_bullet.webp" alt class="h-5" />
              <p>Ranked 4th best university in Karnataka </p>
            </li>

              <li class="flex gap-4 p-2">
              <img src="../assets/landing/why_choose_bullet.webp" alt class="h-5" />
              <p>University has been awarded as best NSS unit and
best NSS program officer by the Ministry of Youth Affairs</p>
            </li>

          </ul>
        </div>

          <div class="flex">
          <ul class="text-left">
            <li class="flex gap-4 p-2">
              <img src="../assets/landing/why_choose_bullet.webp" alt class="h-5" />
              <p>3000 learners on its rolls </p>
            </li>

              <li class="flex gap-4 p-2">
              <img src="../assets/landing/why_choose_bullet.webp" alt class="h-5" />
              <p>Awarded the Skill Tree - Great Place to Study"" at
the House of Commons, London, UK </p>
            </li>

          </ul>
        </div>
      </div>
      </div>

    </section>

    <section class="p-4 lg:block hidden ">
      <div class="max-w-7xl mx-auto text-center">
        <h2 class="font-bold lg:text-3xl text-[20px] sm:text-[20px] text-gray-700 p-4">Program Highlights</h2>
      <div class="relative w-full gap-5 mx-auto py-4">
     
        <div class="">

        
         <div class="flex flex-col lg:flex-row w-full text-sm md:text-base text-center">
                    <div class="lg:w-full flex px-2 flex flex-col justify-center items-center lg:justify-start py-2">
                      <div class="p-6 mb-4 bg-[#94C34D] rounded-full"><img src="../assets/landing/program_highlights_icon_100_online.webp" class=" object-contain" width="30" alt=""></div>
                        <div class="items-center px-8 text-center"> 
                            <h4 class="font-bold text-lg">100% Online</h4>
                            <p>
                               Designed to fit your busy schedule, our online curriculum allows you to learn at your own pace and access course materials from anywhere, at any time.
                            </p>
                            </div>                        
                    </div>

                   <div class="lg:w-full flex px-2 flex flex-col justify-center items-center lg:justify-start py-2">
                      <div class="p-6 mb-4 bg-[#94C34D] rounded-full"><img src="../assets/landing/program_highlights_icon_expert_faculty.webp" class=" object-contain" width="30" alt=""></div>
                        <div class="items-center px-8 text-center"> 
                            <h4 class="font-bold text-lg">Expert Faculty</h4>
                            <p>
                               Learn from experienced educators and industry professionals who provide real-world insights and practical applications of analytics in various domains.
                            </p>
                            </div>                        
                    </div>

                      <div class="lg:w-full flex px-2 flex flex-col justify-center items-center lg:justify-start py-2">
                      <div class="p-6 mb-4 bg-[#94C34D] rounded-full"><img src="../assets/landing/program_highlights_icon_handson_learning.webp" class=" object-contain" width="30" alt=""></div>
                        <div class="items-center px-8 text-center"> 
                            <h4 class="font-bold text-lg">Hands-on Learning</h4>
                            <p>
                               Apply your knowledge to real-world challenges through case studies, projects, and cutting-edge tools.
                            </p>
                            </div>                        
                    </div>

                     
                </div>


  
        </div>
        
      </div></div>
      
    </section>

    <section class="bg-blue-100 p-4 lg:hidden">
      <div class="max-w-7xl mx-auto bg-white rounded-lg shadow-lg text-center">
        <h2 class="font-bold lg:text-3xl text-[20px] sm:text-[20px] text-gray-700 p-2 pt-4">Program Highlights</h2>
      <div class="carousel relative w-[90%] mx-auto py-2">
        <div class="next absolute top-[40%] -right-4 z-10">
          <img src="../assets/right-arrow.png" alt />
        </div>
        <div class="prev absolute top-[40%] -left-4 z-[100]">
          <img src="../assets/left-arrow.png" alt />
        </div>
        <div class="slick">
          <div class="">
             <div class="lg:w-full flex px-2 flex flex-col justify-center items-center lg:justify-start py-2">
                      <div class="p-6 mb-4 bg-[#94C34D] rounded-full"><img src="../assets/landing/program_highlights_icon_100_online.webp" class=" object-contain" width="30" alt=""></div>
                        <div class="items-center px-8 text-center"> 
                            <h4 class="font-bold text-lg">100% Online</h4>
                            <p>
                                Designed to fit your busy schedule, our online curriculum allows you to learn at your own pace and access course materials from anywhere, at any time.
                            </p>
                            </div>                        
                    </div>
        </div>

        <div class="">
              <div class="lg:w-full flex px-2 flex flex-col justify-center items-center lg:justify-start py-2">
                      <div class="p-6 mb-4 bg-[#94C34D] rounded-full"><img src="../assets/landing/program_highlights_icon_100_online.webp" class=" object-contain" width="30" alt=""></div>
                        <div class="items-center px-8 text-center"> 
                            <h4 class="font-bold text-lg">Expert Faculty</h4>
                            <p>
                               Learn from experienced educators and industry professionals who provide real-world insights and practical applications of analytics in various domains.
                            </p>
                            </div>                        
                    </div>
        </div>

            <div class="">
          
            <div class="lg:w-full flex px-2 flex flex-col justify-center items-center lg:justify-start py-2">
                      <div class="p-6 mb-4 bg-[#94C34D] rounded-full"><img src="../assets/landing/program_highlights_icon_100_online.webp" class=" object-contain" width="30" alt=""></div>
                        <div class="items-center px-8 text-center"> 
                            <h4 class="font-bold text-lg">Hands-on Learning</h4>
                            <p>
                               Apply your knowledge to real-world challenges through case studies, projects, and cutting-edge tools.
                            </p>
                            </div>                        
                    </div>
        </div>

    
        </div>
        
      </div></div>
      
    </section>

    
<faq-view/>
  

<lpfooter-view/>
  </div>
</template>

<script>
import LpfooterView from "../components/LpfooterView.vue";
import NavView from "../components/NavView.vue";
import StickyView from '../components/StickyView.vue';
import RankingView from '../components/RankingView.vue'
import FaqView from '../components/FaqView.vue'
import ProgramView from '../components/ProgramView.vue'

export default {

  components: {NavView, FaqView, LpfooterView, RankingView, ProgramView, StickyView},

};
</script>

<style>

</style>