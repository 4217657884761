<template>
     <navigation-view />
  
   <section>
      <div class="w-full px-5 pb-5">
        <div class="max-w-5xl mx-auto w-full">
          <ol
            class="flex gap-2 items-center pt-24 pl-3 font-normal text-[#001c54]"
          >
            <li><a href="/">Home</a></li>
            <li>> Enrollment Data</li>
          </ol>


          <h1
                class="lg:text-4xl lg:pt-2 pt-12 py-2 font-extrabold text-[20px] sm:text-[20px]"
              >
              Learner Enrolment Details
              </h1>
          <div
            class="relative my-8 overflow-x-auto shadow-md sm:rounded-lg border-collapse border border-slate-300"
          >
        
            <table
              class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
            >
              <thead
                class="text-xs text-gray-700 uppercase bg-[#95C24D] text-white"
              >
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 border border-slate-300 w-12"
                  >
                  Batch
                  </th>
                  <th scope="col" class="px-6 py-3 border border-slate-300">
                    Program
                  </th>
                  <th scope="col" class="px-6 py-3 border border-slate-300">
                    Number of Enrolments
                  </th>
                  <th scope="col" class="px-6 py-3 border border-slate-300">
                    Total Enrolments
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white "
                  >
                  &nbsp;
                  </th>
                  <td class="px-6 py-4 border border-slate-300">
                    B. Com
                  </td>
                  <td class="px-6 py-4 border border-slate-300">
                   242
                  </td>
                  <td class="px-6 py-4 ">
                   
                    &nbsp;
                  </td>
                </tr>
                <tr>
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white  "
                  >
                  July 2023
                  </th>
                  <td class="px-6 py-4 border border-slate-300">
                    BBA
                  </td>
                  <td class="px-6 py-4 border border-slate-300">
                   28
                  </td>
                  <td class="px-6 py-4 ">
                    <p>
                        312
                    </p>
                 
                  </td>
                </tr>
                <tr>
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white border-b border-slate-300"
                  >
                 &nbsp;
                  </th>
                  <td class="px-6 py-4 border border-slate-300">
                    BCA
                  </td>
                  <td class="px-6 py-4 border border-slate-300">
                    42
                  </td>
                  <td class="px-6 py-4 border-b border-slate-300">
                    &nbsp;
                 
                  </td>
                </tr>
                <tr>
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white "
                  >
                  &nbsp;
                  </th>
                  <td class="px-6 py-4 border border-slate-300">
                    B. Com
                  </td>
                  <td class="px-6 py-4 border border-slate-300">
                   73
                  </td>
                  <td class="px-6 py-4 ">
                   
                    &nbsp;
                  </td>
                </tr>
                <tr>
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white "
                  >
                  January 2024
                  </th>
                  <td class="px-6 py-4 border border-slate-300">
                    BBA
                  </td>
                  <td class="px-6 py-4 border border-slate-300">
                  40
                  </td>
                  <td class="px-6 py-4 ">
                    <p>
                        146
                    </p>
                 
                  </td>
                </tr>
                <tr>
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                 &nbsp;
                  </th>
                  <td class="px-6 py-4 border border-slate-300">
                    BCA
                  </td>
                  <td class="px-6 py-4 border border-slate-300">
                    33
                  </td>
                  <td class="px-6 py-4">
                    &nbsp;
                 
                  </td>
                </tr>
              
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
    <footer-view />
</template>

<script>
import NavigationView from "../components/NavigationView.vue";
import FooterView from "../components/FooterView.vue";
export default {
    components: {
        NavigationView,
        FooterView,
    },

}
</script>

<style>

</style>