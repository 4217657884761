<template>
  <div>
    <section>
      <div class="w-full  mx-auto my-10 max-w-7xl mb-8">
        <h1
          class="font-extrabold text-3xl text-gray-700 text-center p-4 lg:text-3xl text-[20px] sm:text-[20px]"
        >
          Specializations Offered
        </h1>

        <ul class="flex justify-evenly gap-3 mb-0 list-none">
          <li class="mb-px w-full mr-2 last:mr-0 text-center">
            <button
              class="text-[15px] w-full font-bold uppercase px-5 py-3 sm:shadow-lg sm:rounded block"
              v-on:click="toggleTabs(6)"
              v-bind:class="{
                'text-white bg-gray-500': openTab !== 6,
                'text-white bg-[#94C34D]': openTab === 6,
              }"
            >
              BCOM
            </button>
          </li>
          <li class="mb-px w-full mr-2 last:mr-0 text-center">
          <button class="text-[15px] w-full font-bold uppercase px-5 py-3 sm:shadow-lg sm:rounded block " v-on:click="toggleTabs(7)" v-bind:class="{'text-white bg-gray-500': openTab !== 7, 'text-white bg-[#94C34D]': openTab === 7}">
            BBA
          </button>
        </li>

          <li class="mb-px w-full mr-2 last:mr-0 text-center">
            <button
              class="text-[15px] w-full font-bold uppercase px-5 py-3 sm:shadow-lg sm:rounded block"
              v-on:click="toggleTabs(8)"
              v-bind:class="{
                'text-white bg-gray-500': openTab !== 8,
                'text-white bg-[#94C34D]': openTab === 8,
              }"
            >
              BCA
            </button>
          </li>
        </ul>
        <div class="flex flex-wrap justify-evenly max-w-7xl">
          <div class="flex ">
            <div class="px-4 py-1 flex-auto">
              <div class="tab-content tab-space">
                <div
                  v-bind:class="{ hidden: openTab !== 6, block: openTab === 6 }"
                >
                  <ul class="space-y-4 text-left">
                    <li class="">
                      <div
                        class="flex flex-col gap-4 lg:flex-row justify-evenly"
                      >
                        <div class="my-4">
                          <div
                            class="block lg:w-[600px] w-[90%] sm:w-[90%] mx-auto p-6 bg-white border border-gray-200 rounded-lg shadow-xl"
                          >
                            <img src="../assets/program/spwz_1.webp" alt />
                            <h5
                              class="text-left mb-2 text-base font-bold tracking-tight text-gray-900 dark:text-white pt-2"
                            >
                              B.Com in Accounting and Finance
                            </h5>
                            <div class="flex justify-between items-center pt-4">
                              <div class="flex flex-col gap-4 w-[65%]">
                                <div class="flex gap-4">
                                  <img
                                    src="../assets/program/Live-Online-Classes.png"
                                    alt
                                    class="w-[20px] h-[22px]"
                                  />
                                  <p
                                    class="lg:font-normal text-sm sm:text-sm text-gray-700 dark:text-gray-400"
                                  >
                                    Live Online Classes
                                  </p>
                                </div>
                                <div class="flex gap-4">
                                  <img
                                    src="../assets/program/2-Years-4-Semester.png"
                                    alt
                                    class="w-[20px] h-[22px]"
                                  />
                                  <p
                                    class="lg:font-normal text-sm sm:text-sm text-gray-700 dark:text-gray-400"
                                  >
                                    3 Years | 6 Semesters
                                  </p>
                                </div>
                              </div>
                              <div>
                                <p
                                  class="lg:text-lg font-semibold text-white bg-[#81C8DC] lg:px-4 py-2 px-1 sm:px-1 sm:text-sm rounded-xl"
                                >
                                  <a
                                    href="/programs/online-bcom-accounting-and-finance"
                                    class="flex items-center text-white"
                                    >Know more</a
                                  >
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="my-4">
                          <div
                            class="block lg:w-[600px] w-[90%] sm:w-[90%] mx-auto p-6 bg-white border border-gray-200 rounded-lg shadow-xl"
                          >
                            <img src="../assets/program/spwz_2.webp" alt />
                            <h5
                              class="text-left mb-2 text-base font-bold tracking-tight text-gray-900 dark:text-white pt-2"
                            >
                              B.Com in International Finance and Accounting
                              (Accredited by ACCA, UK)
                            </h5>
                            <div class="flex justify-between items-center pt-4">
                              <div class="flex flex-col gap-4 w-[65%]">
                                <div class="flex gap-4">
                                  <img
                                    src="../assets/program/Live-Online-Classes.png"
                                    alt
                                    class="w-[20px] h-[22px]"
                                  />
                                  <p
                                    class="lg:font-normal text-sm sm:text-sm text-gray-700 dark:text-gray-400"
                                  >
                                    Live Online Classes
                                  </p>
                                </div>
                                <div class="flex gap-4">
                                  <img
                                    src="../assets/program/2-Years-4-Semester.png"
                                    alt
                                    class="w-[20px] h-[22px]"
                                  />
                                  <p
                                    class="lg:font-normal text-sm sm:text-sm text-gray-700 dark:text-gray-400"
                                  >
                                    3 Years | 6 Semesters
                                  </p>
                                </div>
                              </div>
                              <div>
                                <p
                                  class="lg:text-lg font-semibold text-white bg-[#81C8DC] lg:px-4 py-2 px-1 sm:px-1 sm:text-sm rounded-xl"
                                >
                                  <a
                                    href="/programs/online-bcom-international-finance-and-accounting"
                                    class="flex items-center text-white"
                                    >Know more</a
                                  >
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div
                  v-bind:class="{ hidden: openTab !== 7, block: openTab === 7 }"
                >
                  <ul class="space-y-4 text-left">
                    <li class="">
                      <div
                        class="flex flex-col gap-4 lg:flex-row justify-evenly"
                      >
                        <div class="my-4">
                          <div
                            class="block lg:w-[600px] w-[90%] sm:w-[90%] mx-auto p-6 bg-white border border-gray-200 rounded-lg shadow-xl"
                          >
                            <img src="../assets/program/yp-bba-hrm.webp" alt />
                            <h5
                              class="text-left mb-2 text-base font-bold tracking-tight text-gray-900 dark:text-white pt-2"
                            >
                              BBA in General Management
                            </h5>
                            <div class="flex justify-between items-center pt-4">
                              <div class="flex flex-col gap-4 w-[65%]">
                                <div class="flex gap-4">
                                  <img
                                    src="../assets/program/Live-Online-Classes.png"
                                    alt
                                    class="w-[20px] h-[22px]"
                                  />
                                  <p
                                    class="lg:font-normal text-sm sm:text-sm text-gray-700 dark:text-gray-400"
                                  >
                                    Live Online Classes
                                  </p>
                                </div>
                                <div class="flex gap-4">
                                  <img
                                    src="../assets/program/2-Years-4-Semester.png"
                                    alt
                                    class="w-[20px] h-[22px]"
                                  />
                                  <p
                                    class="lg:font-normal text-sm sm:text-sm text-gray-700 dark:text-gray-400"
                                  >
                                    3 Years | 6 Semesters
                                  </p>
                                </div>
                              </div>
                              <div>
                                <p
                                  class="lg:text-lg font-semibold text-white bg-[#81C8DC] lg:px-4 py-2 px-1 sm:px-1 sm:text-sm rounded-xl"
                                >
                                  <a
                                    href="/programs/online-bba-general-management"
                                    class="flex items-center text-white"
                                    >Know more</a
                                  >
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
<!-- 
                        <div class="my-4">
                          <div
                            class="block lg:w-[600px] w-[90%] sm:w-[90%] mx-auto p-6 bg-white border border-gray-200 rounded-lg shadow-xl"
                          >
                            <img
                              src="../assets/program/yp-bba-finance.webp"
                              alt
                            />
                            <h5
                              class="text-left mb-2 text-base font-bold tracking-tight text-gray-900 dark:text-white pt-2"
                            >
                              BBA in Finance
                            </h5>
                            <div class="flex justify-between items-center pt-4">
                              <div class="flex flex-col gap-4 w-[65%]">
                                <div class="flex gap-4">
                                  <img
                                    src="../assets/program/Live-Online-Classes.png"
                                    alt
                                    class="w-[20px] h-[22px]"
                                  />
                                  <p
                                    class="lg:font-normal text-sm sm:text-sm text-gray-700 dark:text-gray-400"
                                  >
                                    Live Online Classes
                                  </p>
                                </div>
                                <div class="flex gap-4">
                                  <img
                                    src="../assets/program/2-Years-4-Semester.png"
                                    alt
                                    class="w-[20px] h-[22px]"
                                  />
                                  <p
                                    class="lg:font-normal text-sm sm:text-sm text-gray-700 dark:text-gray-400"
                                  >
                                    3 Years | 6 Semesters
                                  </p>
                                </div>
                              </div>
                              <div>
                                <p
                                  class="lg:text-lg font-semibold text-white bg-[#81C8DC] lg:px-4 py-2 px-1 sm:px-1 sm:text-sm rounded-xl"
                                >
                                  <a
                                    href="/programs/online-bba-finance"
                                    class="flex items-center text-white"
                                    >Know more</a
                                  >
                                </p>
                              </div>
                            </div>
                          </div>
                        </div> -->
                      </div>
                    </li>

                    <!-- <li class="">
                      <div class="my-4">
                        <div
                          class="block lg:w-[600px] w-[90%] sm:w-[90%] mx-auto p-6 bg-white border border-gray-200 rounded-lg shadow-xl"
                        >
                          <img
                            src="../assets/program/yp-bba-marketing.webp"
                            alt
                          />
                          <h5
                            class="text-left mb-2 text-base font-bold tracking-tight text-gray-900 dark:text-white pt-2"
                          >
                            BBA in Marketing
                          </h5>
                          <div class="flex justify-between items-center pt-4">
                            <div class="flex flex-col gap-4 w-[65%]">
                              <div class="flex gap-4">
                                <img
                                  src="../assets/program/Live-Online-Classes.png"
                                  alt
                                  class="w-[20px] h-[22px]"
                                />
                                <p
                                  class="lg:font-normal text-sm sm:text-sm text-gray-700 dark:text-gray-400"
                                >
                                  Live Online Classes
                                </p>
                              </div>
                              <div class="flex gap-4">
                                <img
                                  src="../assets/program/2-Years-4-Semester.png"
                                  alt
                                  class="w-[20px] h-[22px]"
                                />
                                <p
                                  class="lg:font-normal text-sm sm:text-sm text-gray-700 dark:text-gray-400"
                                >
                                  3 Years | 6 Semesters
                                </p>
                              </div>
                            </div>
                            <div>
                              <p
                                class="lg:text-lg font-semibold text-white bg-[#81C8DC] lg:px-4 py-2 px-1 sm:px-1 sm:text-sm rounded-xl"
                              >
                                <a
                                  href="/programs/online-bba-marketing"
                                  class="flex items-center text-white"
                                  >Know more</a
                                >
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li> -->
                  </ul>
                </div>
                <div
                  v-bind:class="{ hidden: openTab !== 8, block: openTab === 8 }"
                >
                  <ul class="space-y-4 text-left flex justify-center items-center flex-wrap">
                    <li class="m-2">
                      <div class="my-4">
                        <div
                          class="block lg:w-[600px] w-[90%] sm:w-[90%] mx-auto p-6 bg-white border border-gray-200 rounded-lg shadow-xl"
                        >
                          <img
                            src="../assets/program/yp-bca-in-computer-science-and-it.webp"
                            alt
                          />
                          <h5
                            class="text-left mb-2 text-base font-bold tracking-tight text-gray-900 dark:text-white pt-2"
                          >
                            BCA in Computer Science and IT
                          </h5>
                          <div class="flex justify-between items-center pt-4">
                            <div class="flex flex-col gap-4 w-[65%]">
                              <div class="flex gap-4">
                                <img
                                  src="../assets/program/Live-Online-Classes.png"
                                  alt
                                  class="w-[20px] h-[22px]"
                                />
                                <p
                                  class="lg:font-normal text-sm sm:text-sm text-gray-700 dark:text-gray-400"
                                >
                                  Live Online Classes
                                </p>
                              </div>
                              <div class="flex gap-4">
                                <img
                                  src="../assets/program/2-Years-4-Semester.png"
                                  alt
                                  class="w-[20px] h-[22px]"
                                />
                                <p
                                  class="lg:font-normal text-sm sm:text-sm text-gray-700 dark:text-gray-400"
                                >
                                  3 Years | 6 Semesters
                                </p>
                              </div>
                            </div>
                            <div>
                              <p
                                class="lg:text-lg text-sm font-semibold text-white bg-[#81C8DC] lg:px-4 py-2 px-1 sm:px-1 sm:text-sm rounded-xl"
                              >
                                <a
                                  href="/programs/online-bca-computer-science-and-it"
                                  class="flex items-center text-white"
                                  >Know more</a
                                >
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li class="m-2">
                      <div class="my-4">
                        <div
                          class="block lg:w-[600px] w-[90%] sm:w-[90%] mx-auto p-6 bg-white border border-gray-200 rounded-lg shadow-xl"
                        >
                          <img
                            src="../assets/program/yp-bca-in-computer-science-and-it.webp"
                            alt
                          />
                          <h5
                            class="text-left mb-2 text-base font-bold tracking-tight text-gray-900 dark:text-white pt-2"
                          >
                            BCA in Cloud Computing & Cyber Security
                          </h5>
                          <div class="flex justify-between items-center pt-4">
                            <div class="flex flex-col gap-4 w-[65%]">
                              <div class="flex gap-4">
                                <img
                                  src="../assets/program/Live-Online-Classes.png"
                                  alt
                                  class="w-[20px] h-[22px]"
                                />
                                <p
                                  class="lg:font-normal text-sm sm:text-sm text-gray-700 dark:text-gray-400"
                                >
                                  Live Online Classes
                                </p>
                              </div>
                              <div class="flex gap-4">
                                <img
                                  src="../assets/program/2-Years-4-Semester.png"
                                  alt
                                  class="w-[20px] h-[22px]"
                                />
                                <p
                                  class="lg:font-normal text-sm sm:text-sm text-gray-700 dark:text-gray-400"
                                >
                                  3 Years | 6 Semesters
                                </p>
                              </div>
                            </div>
                            <div>
                              <p
                                class="lg:text-lg text-sm font-semibold text-white bg-[#81C8DC] lg:px-4 py-2 px-1 sm:px-1 sm:text-sm rounded-xl"
                              >
                                <a
                                  href="/programs/online-bca-cloud-computing-and-cyber-security"
                                  class="flex items-center text-white"
                                  >Know more</a
                                >
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="m-2">
                      <div class="my-4">
                        <div
                          class="block lg:w-[600px] w-[90%] sm:w-[90%] mx-auto p-6 bg-white border border-gray-200 rounded-lg shadow-xl"
                        >
                          <img
                            src="../assets/program/yp-bca-in-computer-science-and-it.webp"
                            alt
                          />
                          <h5
                            class="text-left mb-2 text-base font-bold tracking-tight text-gray-900 dark:text-white pt-2"
                          >
                            BCA in Animation and Graphic Design
                          </h5>
                          <div class="flex justify-between items-center pt-4">
                            <div class="flex flex-col gap-4 w-[65%]">
                              <div class="flex gap-4">
                                <img
                                  src="../assets/program/Live-Online-Classes.png"
                                  alt
                                  class="w-[20px] h-[22px]"
                                />
                                <p
                                  class="lg:font-normal text-sm sm:text-sm text-gray-700 dark:text-gray-400"
                                >
                                  Live Online Classes
                                </p>
                              </div>
                              <div class="flex gap-4">
                                <img
                                  src="../assets/program/2-Years-4-Semester.png"
                                  alt
                                  class="w-[20px] h-[22px]"
                                />
                                <p
                                  class="lg:font-normal text-sm sm:text-sm text-gray-700 dark:text-gray-400"
                                >
                                  3 Years | 6 Semesters
                                </p>
                              </div>
                            </div>
                            <div>
                              <p
                                class="lg:text-lg text-sm font-semibold text-white bg-[#81C8DC] lg:px-4 py-2 px-1 sm:px-1 sm:text-sm rounded-xl"
                              >
                                <a
                                  href="/programs/online-bca-animation-and-graphic-design"
                                  class="flex items-center text-white"
                                  >Know more</a
                                >
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      is_widget: false,
      showMenu: false,
      openTab: 6,
    };
  },
  created() {
    setTimeout(() => {
      this.is_widget = true;
    }, 2000);
  },

  // methods: {
  //   activeTabOne() {
  //     this.tab = 1;
  //   },
  //   activeTabTwo() {
  //     this.tab = 2;
  //   },
  //   activeTabThree() {
  //     this.tab = 3;
  //   },
  // },

  name: "pink-tabs",

  methods: {
    toggleTabs: function (tabNumber) {
      this.openTab = tabNumber;
    },
  },
};
</script>

<style></style>
