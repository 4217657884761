<template>
    <div>
      <navigation-view/>
      <div class="bg-[#94C34D]">
      <div class="w-full h-full relative">
              <a href="https://apply.onlineyenepoya.com/" target="_blank">
                <div class="flex max-w-7xl mx-auto place-items-center text-white justify-between">
                  <div class="mx-auto text-left lg:text-left pt-6">
                    <div class="flex flex-wrap  lg:w-[50%]  bg-white rounded-lg border-1 p-4"> 
   <h2 class="lg:text-3xl pt-2 py-2 text-black font-extrabold text-[30px] sm:text-[30px]">Thank You</h2>
   <p class="text-black">Your Enquiry has been submitted successfully. Our admissions team will get in touch with you soon. A verification email has been sent to your registered email ID.
Please ignore if already verified.</p>

  </div> 
                    <div class="relative sm:block lg:hidden pt-2">
                      <div class="flex justify-center -mr-8">
                        <img src="../assets/banner2.webp" class="w-[20rem] -ml-8 lg:mr-0" alt />
                      </div>
                      <!-- <div
                        class="absolute top-10 -right-14 drop-shadow-2xl py-6 px-6 bg-no-repeat bg-contain banner1"
                      >
                        <img src="../assets/banner/banner-icon1.png" class="mx-auto" />
                        <span class="text-black leading-5">
                          <p>Outcome</p>
                          <p>Based</p>
                          <p>Education</p>
                        </span>
                      </div> -->
                   
                    </div>

                  
                  </div>

                  <div class="relative hidden sm:hidden lg:block">
                    <img src="../assets/banner2.webp" class="w-[33rem]" alt />
                    <!-- <div
                      class="absolute top-10 left-[27rem] drop-shadow-2xl pb-12 pt-6 px-8 bg-no-repeat banner1"
                    >
                      <img src="../assets/banner/banner-icon1.png" alt />
                      <span class="text-black leading-5">
                        <p>Outcome</p>
                        <p>Based</p>
                        <p>Education</p>
                      </span>
                    </div> -->
                  </div>
                </div>
              </a>
            </div></div>
      <ranking-view/>
  
       <section class="max-w-7xl mx-auto p-4">
        <h1
          class="font-extrabold text-gray-700 p-4  lg:text-3xl text-[20px] sm:text-[20px]"
        >
          About Yenepoya (Deemed to be University)
        </h1>
        <div class="lg:flex gap-6 place-items-center">
          <img
            src="../assets/about/university.png"
            width="550"
            class="mx-auto p-4"
            alt=""
          />
          <span class="text-left readmore-section">
            <p class="pb-5">
             Yenepoya University, a not-for-profit trust, was established in 1991 by entrepreneur Mr. Yenepoya
  Abdulla Kunhi as its Chairman, with a commitment of uplifting the minorities in general. From its
  inception, the management dreamt of providing quality higher education and hired the best in their
  profession towards this goal. The university aims to foster academic excellence and global
  competencies among students in diverse domains like management, technology, commerce, etc. It
  envisions creating a vibrant knowledge capital and inspiring the leaders of tomorrow who can take
  this country to the forefront of developed nations. The National Accreditation and Assessment
  Council (NAAC) accredited the university with an “A+” grade in August 2022. </p>
           
    
          <p class="flex align-middle place-content-end gap-2">
                  <a href="/aboutus" class="readmore-btn text-gray-600">Read More</a><img src="../assets/about/read-more.png" width="25" alt="">
              </p>
          </span>
        </div>
      </section>
      <!-- <about-view/> -->
      <program-view/>
      <skillhome-view/>
      <!-- <future-view/>
      <faculty-view/> -->
      <!-- <learn-view/> -->
      <faq-view/>
      <footer-view-vue/>
      <sticky-view/>
    </div>
  </template>
  
  <script>
  // import FutureView from '../components/FutureView.vue'
  // import FacultyView from '../components/FacultyView.vue'
  import FaqView from '../components/FaqView.vue'
  // @ is an alias to /src
  import FooterViewVue from '../components/FooterView.vue'
  // import LearnView from '../components/LearnView.vue'
  import NavigationView from '../components/NavigationView.vue'
  import ProgramView from '../components/ProgramView.vue'
  import RankingView from '../components/RankingView.vue'
  // import AboutView from "../components/AboutView.vue"
  import StickyView from '../components/StickyView.vue'
  import SkillhomeView from '@/components/SkillhomeView.vue'
  
  export default {

    components: {
      NavigationView,
      // AboutView,
      FooterViewVue,
      RankingView,
      ProgramView,
      StickyView,
      SkillhomeView,
      // FutureView,
      // FacultyView,
      // LearnView,
      FaqView,
    }
  }
  </script>
  