<template>
  <div>
    <section>
      <div class="flex items-center justify-center">
        <div class="relative bg-white rounded-lg shadow-sm">
          <h4 class="font-bold text-lg px-2 py-2">
            Download Brochure
            <br />
          </h4>
          <div class>
            <form
              class="space-y-3 p-4 font-normal"
              @submit="$emit('closeWidget')"
              @submit.prevent="brochureSubmit"
            >
              <input
                type="text"
                placeholder="Enter Name *"
                id="FirstName"
                class="p-1 rounded-sm w-full border-gray-300"
                v-model="formInfo.firstName"
              />
              <input
                type="email"
                placeholder="Enter Email Address *"
                id="EmailAddress"
                class="p-1 rounded-sm w-full border-gray-300"
                v-model="formInfo.email"
              />

              <input
              type="tel"
              placeholder="Enter Mobile Number *"
              id="phone" maxlength="10"
              onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
              class="p-1 rounded-sm w-full border-gray-300"
              required
            />
              <!-- <div class="flex flex-row">
                <input
                  type="tel"
                  placeholder="Enter Mobile Number *"
                  id="Phone"
                  maxlength="10"
                  onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                  class="p-1 flex lg:w-[83%] w- rounded-sm w-full border-gray-300"
                  v-model="formInfo.phone"
                  required
                />
                <button
                  @click.prevent="sendOTP()"
                  class="float-right p-1 bg-[#94C34D] text-sm text-white"
                >Get OTP</button>
              </div> -->
              <select
                name="mx_Program"
                id
                value="mx_Program"
                aria-placeholder="Select Program"
                class="p-1 rounded-sm w-full border-gray-300 text-base hidden"
                required
              >
                <option value="B.Com">BBA</option>
              </select>
              <select
                name="mx_Elective"
                id="mx_Elective"
                aria-placeholder="Select Elective"
                class="p-1 rounded-sm w-full border-gray-300 text-base hidden"
              >
                <option value="none">Select Specialization *</option>
                <option value="Accounting and Finance">Human Resource Management</option>
                
              </select>

              <button
                type="submit"
                class="w-full text-white bg-[#9C9B9D] hover:bg-[#94C34D] rounded-lg px-4 py-2 text-center"
              >Download Brochure &#10148;</button>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
  
   <script>
import axios from "axios";
export default {
  data() {
    return {
      formInfo: {
        firstName: "",
        email: "",
        phone: ""
      }
    };
  },
  props: ["widget_prop_id"],
  created() {
    this.widget_id = this.widget_prop_id;
  },
  methods: {
    brochureSubmit(el) {
      console.log("formInput:::", el);
      let dataJson = [
        {
          Attribute: "FirstName",
          Value: el.target.elements.FirstName.value
        },
        {
          Attribute: "EmailAddress",
          Value: el.target.elements.EmailAddress.value
        },
        {
          Attribute: "Phone",
          Value: el.target.elements.phone.value
        },
        {
          Attribute: "mx_Program",
          Value: "BBA"
        },

        {
          Attribute: "mx_Elective",
          Value: "Human Resource Management"
        }
      ];
      console.log(dataJson);
      axios
        .post(
          "https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$rca456e8ab1c2865cfece22cda05cbb0c&secretKey=3c0ddb27e7dc562e5c6c08bb8c11b02a9019e041",
          dataJson,{cross: true}
        )
        .then(() => {
          // console.log("update response: ",response)
          // const formToReset = document.getElementById("brochure_form");
          // formToReset.reset();
          var link = document.createElement("a");
          link.href = "/brochure/Yenepoya_BBA-HRM.pdf";
          link.target = "_blank";
          link.download = "Overall-Brochure.pdf";
          document.body.appendChild(link);
          console.log(link);
          link.click();
          window.location.href = "/brochure/bba-thankyou";
        })
        .catch(error => {
          // console.log(error.response.data.ExceptionMessage)
          console.log(error);
        });
    },
   
    }
  };

</script>
  <style >

</style>