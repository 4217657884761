<template>
  <div>
   
    <div class="max-w-6xl mx-auto w-full h-full">
      <div class="bg-white py-2 sm:py-4 md:h-[20%]">
        <h1
          class="text-gray-500 flex-wrap text-xs lg:text-sm 2xl:text-base text-start font-thin flex gap-1 lg:gap-4 items-center px-6 lg:px-2"
        >
          <router-link :to="{ name: 'home' }">
            <span class="font-normal"> Home </span></router-link
          >
          <span class="font-normal"> > </span>
          <span class="font-normal"> Blogs</span>
        </h1>
      </div>
    </div>
    <div class="bg-[#f5f5f5] py-4 w-full">
      <div
        class="max-w-6xl mx-auto w-full h-full flex flex-row overflow-x-scroll lg:overflow-x-auto gap-6 px-6 lg:px-2"
      >
        <button
          @click="this.blogsCategory"
          :class="[this.selected_category == 'all' ? 'text-gray-500' : '']"
          class="font-semibold text-black text-sm"
        >
          All
        </button>
        <template v-for="category in this.blog_list" :key="category">
          <button
            class="font-semibold text-black text-sm whitespace-nowrap"
            :class="[
              this.selected_category == category.id ? 'text-gray-500' : '',
            ]"
            @click="blogsElements(category.id)"
          >
            {{ category.name }}
          </button>
        </template>
      </div>
    </div>

    <div class="max-w-6xl mx-auto py-8">
      <div class="flex flex-wrap gap-5 justify-start px-8 lg:px-2">
        <template v-for="el in this.blog_elements" :key="el">
          <div
            @click="this.selectedBlog(el)"
            class="w-full md:w-[48%] lg:w-[32%] cursor-pointer shadow-md rounded-md"
          >
            <div class="flex flex-col gap-2 pb-2">
              <div class="w-full relative rounded-t-md">
                <img :src="el.image" alt="" class="w-full rounded-md" />

                <!-- <div
                    class="absolute bottom-0 bg-black text-yellow-500 text-sm px-2 py-0.5 rounded"
                  >
                    Finance
                  </div> -->
              </div>

              <div class="px-2">
                <div class="flex flex-row justify-between px-2">
                  <p class="text-xs lg:text-sm font-normal">June 28, 2023</p>
                  <!-- <p class="text-xs lg:text-sm font-normal">By Arjun Mohan</p> -->
                </div>

                <h1
                  class="text-start font-semibold text-sm lg:text-base mt-2 px-2"
                >
                  {{ el.title }}
                </h1>

                <p
                  class="text-xs lg:text-sm mt-1 line-clamp-3 text-justify px-2"
                >
                  {{ el.short_desc }}
                </p>
              </div>
            </div>
          </div>
        </template>
      </div>

      <div
        v-if="this.blog_elements.length > 10"
        class="flex justify-center mt-4"
      >
        <button
          class="bg-yellow-500 rounded-md px-2 py-1 font-medium text-black text-sm"
        >
          View More
        </button>
      </div>
    </div>

  </div>
</template>

<script>
import axios from "axios";


export default {
  name: "BlogListing",
  data() {
    return {
      selected_category: "",
      blog_list: [],
      blog_elements: [],
    };
  },
  components: {
   
  },
  async created() {
    await this.blogsCategory();
  },
  methods: {
    async blogsCategory() {
      await axios
        .get(`${process.env.VUE_APP_API}/blog-category/list/`)
        .then((resp) => {
          if (resp.data.status == 200) {
            this.blog_list = resp.data.data;
            this.blogsElements();
          }
        })
        .catch((e) => {
          console.error(e.message);
        });
    },

    async blogsElements(category_id = "") {
      if (category_id != "") {
        this.selected_category = category_id;
      } else {
        this.selected_category = "all";
      }
      await axios
        .get(`${process.env.VUE_APP_API}/blog/list/?category_id=${category_id}`)
        .then((resp) => {
          if (resp.data.status == 200) {
            this.blog_elements = resp.data.data;
          }
        })
        .catch((e) => {
          console.error(e.message);
        });
    },
    async selectedBlog(blog) {
      this.$router.push({
        name: "SelectedBlog",
        params: {
          blog: blog.slug,
        },
      });
    },
  },
};
</script>
