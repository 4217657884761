<template>
  <div class="home">
    <navigation-view/>
    <banner-view/>
    <ranking-view/>

     <section class="max-w-7xl mx-auto p-4">
      <h1
        class="font-extrabold text-gray-700 p-4  lg:text-3xl text-[20px] sm:text-[20px]"
      >
        About Yenepoya (Deemed to be University)
      </h1>
      <div class="lg:flex gap-6 place-items-center">
        <img
          src="../assets/about/university.png"
          width="550"
          class="mx-auto p-4"
          alt=""
        />
        <span class="text-left readmore-section">
          <p class="pb-5">
           Yenepoya University, a not-for-profit trust, was established in 1991 by entrepreneur Mr. Yenepoya
Abdulla Kunhi as its Chairman, with a commitment of uplifting the minorities in general. From its
inception, the management dreamt of providing quality higher education and hired the best in their
profession towards this goal. The university aims to foster academic excellence and global
competencies among students in diverse domains like management, technology, commerce, etc. It
envisions creating a vibrant knowledge capital and inspiring the leaders of tomorrow who can take
this country to the forefront of developed nations. The National Accreditation and Assessment
Council (NAAC) accredited the university with an “A+” grade in August 2022. </p>
         
  
        <p class="flex align-middle place-content-end gap-2">
                <a href="/aboutus" class="readmore-btn text-gray-600">Read More</a><img src="../assets/about/read-more.png" width="25" alt="">
            </p>
        </span>
      </div>
    </section>
    <!-- <about-view/> -->
    <program-view/>
    <skillhome-view/>
    <!-- <future-view/>
    <faculty-view/> -->
    <!-- <learn-view/> -->
    <faq-view/>
    <footer-view-vue/>
    <sticky-view/>
  </div>
</template>

<script>
import BannerView from '../components/BannerView.vue';
// import FutureView from '../components/FutureView.vue';
// import FacultyView from '../components/FacultyView.vue';
import FaqView from '../components/FaqView.vue';
// @ is an alias to /src
import FooterViewVue from '../components/FooterView.vue';
// import LearnView from '../components/LearnView.vue';
import NavigationView from '../components/NavigationView.vue';
import ProgramView from '../components/ProgramView.vue';
import RankingView from '../components/RankingView.vue';
// import AboutView from "../components/AboutView.vue";
import StickyView from '../components/StickyView.vue';
import SkillhomeView from '@/components/SkillhomeView.vue';
import { useHead } from "@vueuse/head";

export default {
  name: 'HomeView',
  components: {
    NavigationView,
    BannerView,
    // AboutView,
    FooterViewVue,
    RankingView,
    ProgramView,
    StickyView,
    SkillhomeView,
    // FutureView,
    // FacultyView,
    // LearnView,
    FaqView,
  },

  setup() {
    useHead({
      // Can be static or computed
      title: "Online Degree Program for Under Graduates  | Yenepoya Online",
      meta: [
        {
          name: `description`,
          content:
            "Join Yenepoya Online and equip yourself with an Online Bachelors Degree. Expand horizons through our engaging and flexible digital learning.",
        },
      ],

      link: [
        {
          rel: "canonical",
          href: "https://onlineyenepoya.com/",
        },
      ],
    });
  },
  
}
</script>
