<template>
  <div class="contact">
    <navigation-view />

    <section>
      <div class="bg-[#94C34D]">
        <div class="flex max-w-7xl mx-auto place-items-center text-white justify-between">
          <div class="mx-auto text-center lg:text-left pt-6">
            <h1
              class="lg:text-[3rem] text-[28px] font-bold lg:leading-[60px] sm:text-[30px] leading-10"
            >Reach Us</h1>
            <div class="mx-auto w-[70%]text-center m-4 md:block lg:hidden">
              <div class="p-1 m-1 flex">
                <img src="../assets/contactus/banner_location.webp" alt class="mx-auto" />
              </div>
            </div>
            <hr class="max-w-[20%] bg-white h-1 rounded-xl my-4 mx-auto lg:mx-0 sm:block lg:hidden" />
            <hr class="max-w-[100%] bg-white h rounded-xl my-4 mx-auto lg:mx-0 lg:block hidden" />
            <div class>
              <h3 class="lg:text-2xl font-bold text-[1.5rem] sm:text-[2rem]">
                YENEPOYA
                <br />(Deemed to be University)
              </h3>
              <h5 class="text-xl font-bold">Admissions open</h5>
            </div>
            <div class="relative sm:block lg:hidden">
              <div class="flex justify-center pt-4 mx-auto">
                <img
                  src="../assets/contactus/banner_main_girl.webp"
                  class="w-[18rem] -mr-1 lg:mr-0"
                  alt
                />
              </div>
            </div>
          </div>
          <div class="relative hidden lg:block">
            <img src="../assets/contactus/banner_main_girl.webp" class="w-[35rem]" alt />
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="w-full bg-[#F8FDFF]">
        <div
          class="w-full mx-auto max-w-7xl py-16 flex flex-col lg:flex-row justify-between gap-20"
        >
          <div class="w-full flex flex-col justify-center">
            <div
              class="relative flex flex-col justify-center items-center gap-5 p-5 border-b border-[#B9BDC0] before:content-[''] before:absolute before:-bottom-[19px] before:right-0 before:w-[1px] before:h-[35px] before:bg-[#B9BDC0] after:content-[''] after:absolute after:-bottom-[19px] after:left-0 after:w-[1px] after:h-[35px] after:bg-[#B9BDC0]"
            >
              <div>
                <img src="../assets/contactus/phone_icon.webp" alt class="w-[80%]" />
              </div>
              <div>
                <p class="text-[#8CB648] text-lg font-semibold">Call Us</p>
                <P class="text-lg text-black">+91 6361 505 801</P>
              </div>
              <div>
                <p class="text-[#8CB648] text-lg font-semibold">What's App</p>
                <p class="text-lg text-black">6361 505 801</p>
              </div>
            </div>
            <div class="flex flex-col justify-center items-center gap-5 pt-10 p-5">
              <div>
                <img src="../assets/contactus/mail_icon.webp" alt />
              </div>
              <div>
                <p class="text-[#8CB648] text-lg font-semibold">Mail Us</p>
                <P class="text-lg text-black">enroll@onlineyenepoya.com</P>
              </div>
            </div>
          </div>
          <div
            class="lg:w-full w-[90%] sm:w-[90%] justify-center mx-auto "
          >
            <div class="w-full">
             
    <!-- <enqire-view/> -->
    <LsqView />


            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="mapouter">
        <div class="gmap_canvas">
          <iframe
            class="gmap_iframe"
            width="100%"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            src="https://maps.google.com/maps?width=2048&amp;height=535&amp;hl=en&amp;q=Yenepoya (Deemed to be University) University Road Deralakatte Mangalore &amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          ></iframe>
          <a href="https://pdflist.com/" alt="pdf">Pdf</a>
        </div>
      </div>
    </section>
    <footer-view />
  </div>
</template>

<script>
import FooterView from "../components/FooterView.vue";
import NavigationView from "../components/NavigationView.vue";
// import EnqireView from "../components/EnqireView.vue";
import LsqView from '../components/LsqView.vue';

export default {
  components: { NavigationView, FooterView, LsqView }
};
</script>
<style>
.mapouter {
  position: relative;
  text-align: right;
  width: 100%;
  height: 535px;
}
.gmap_canvas {
  overflow: hidden;
  background: none !important;
  width: 100%;
  height: 535px;
}
.gmap_iframe {
  height: 530px !important;
}
</style>
