<template>
  <div>

    <section class="sticky top-0 bg-white z-50 shadow-lg">
<nav class="bg-white shadow-lg p-4">
    <div class="max-w-7xl mx-auto px-4 lg:flex justify-between place-items-center text-xl font-semibold">
    <div class="md:flex gap-6 items-center text-center">
        <a href="/">
      <img src="../assets/logo.webp" class="sm:hidden hidden w-[250px] lg:block" alt="">
      <img src="../assets/logo.webp" class="w-[10rem] sm:block lg:hidden" alt=""></a>
    </div>
    </div>
</nav>
   </section>
  </div>
</template>

<script>

export default {
  
};
</script>


<style>
</style>
