<template>
  <div class="fixed inset-0 z-50 overflow-y-auto w-full bg-black bg-opacity-60">
    <div class="flex items-center justify-center min-h-screen">
      <div class="relative bg-white rounded-lg h-[530px] w-[450px] z-60 p-4">
        <div class="npf_wgts" data-height="544px" :data-w="this.widget_id"></div>
        <button
          @click="$emit('closeWidget')"
          class="absolute top-0 right-1 bg-white rounded-full p-1"
        >
          <svg
            class="h-6 w-6 fill-current text-gray-700 hover:text-gray-600 transition duration-150 ease-in-out"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              fill-rule="evenodd"
              d="M10 8.586l4.95-4.95 1.414 1.414L11.414 10l4.95 4.95-1.414 1.414L10 11.414l-4.95 4.95-1.414-1.414L8.586 10 3.636 5.05l1.414-1.414L10 8.586z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
       
        <div class>
          <!-- <form
            class="space-y-3 p-4 font-normal"
            @submit="$emit('closeWidget')"
            @submit.prevent="enquireSubmit"
          >
            <input
              type="text"
              placeholder="Enter Name *"
              id="name"
              class="p-1 rounded-sm w-full border-gray-300"
              required
            />
            <input
              type="email"
              placeholder="Enter Email Address *"
              id="email"
              class="p-1 rounded-sm w-full border-gray-300"
              required
            />
            <input
              type="tel"
              placeholder="Enter Mobile Number *"
              id="phone"
              maxlength="10"
              onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
              class="p-1 rounded-sm w-full border-gray-300"
              required
            />
            <div class="flex flex-row-reverse gap-4">
              <select
                id="city"
                aria-placeholder="Select State"
                class="p-1 rounded-sm w-full border-gray-300 text-base"
                required
              >
                <option>Select city*</option>
                <option v-for="i in this.selected_city.districts" :key="i" :value="i">{{ i }}</option>
              </select>

              <select
                name="state"
                id
                @change="this.selectDistricts"
                class="p-1 rounded-sm w-full border-gray-300 text-base"
                required
              >
                <option>Select State*</option>
                <option v-for="i in this.state_list" :key="i" :value="i.state">{{ i.state}}</option>
              </select>
            </div>
            <select
              name="program"
              id
              value="course"
              aria-placeholder="Select Program"
              class="p-1 rounded-sm w-full border-gray-300 text-base"
              required
            >
              <option value="none">Select Program *</option>
              <option value="B.Com">B.Com</option>
              <option value="BBA">BBA</option>
              <option value="BCA">BCA</option>
            </select>
            <select
              name="elective"
              id="elective"
              aria-placeholder="Select Elective"
              class="p-1 rounded-sm w-full border-gray-300 text-base"
            >
              <option value="none">Select Specialization *</option>
              <option value="Accounting and Finance">Accounting and Finance</option>
              <option
                value="International Finance and Accounting"
              >International Finance and Accounting (Accredited by ACCA, UK)</option>
              <option value="International Finance and Accounting">Human Resource Management</option>
              <option value="International Finance and Accounting">Finance</option>
              <option value="International Finance and Accounting">Marketing</option>
              <option value="International Finance and Accounting">Computer Science and IT</option>
            </select>
            <div class="flex gap-4 text-xs items-start text-left">
              <input type="checkbox" checked />
              <span
                class="text-justify"
              >I authorize Yenepoya to contact me with updates / notifications via Email / SMS / Whatsapp / Call, which overrides DND/NDNC registration *</span>
            </div>
            <button
              type="submit"
              class="w-full text-white bg-[#9C9B9D] hover:bg-[#94C34D] rounded-lg px-4 py-2 text-center"
            >Apply Now &#10148;</button>
          </form> -->
    <lsq-view />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LsqView from '../components/LsqView.vue';
// import axios from "axios";
// import $ from "jquery";
export default {
  components:{
    LsqView
  },
  props: ["widget_prop_id"],
  created() {
    this.widget_id = this.widget_prop_id;
  },

 
};
</script>
<style></style>